.ui.button.primary-button {
  background-color: var(--boschBlue50);
  border: 1px solid var(--boschBlue50);
  color: var(--boschWhite);
  font-weight: normal;
}

.ui.button.secondary-button {
  background-color: var(--boschWhite);
  border: 1px solid var(--boschBlue50);
  color: var(--boschBlue50);
  font-weight: normal;
}

.ui.button.primary-button:hover {
  background-color: var(--boschBlue40);
  border: 1px solid var(--boschBlue40);
  color: var(--boschWhite);
}

.ui.button.secondary-button:hover {
  background-color: var(--boschWhite);
  border: 1px solid var(--boschBlue40);
  color: var(--boschBlue40);
}
