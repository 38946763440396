.ui.form .field .dropdown {
  background-color: #efeff0;
  border-radius: 0;
  color: #000000;
}

.ui.form .field .dropdown:focus {
  border-color: #005691;
  border-radius: 0;
}

.ui.form .error.field .dropdown .icon {
  background-color: #fff6f6;
}

.ui.selection.active.dropdown,
.ui.selection.active.dropdown:hover {
  border-color: #005691;
}

.ui.selection.active.dropdown .menu,
.ui.selection.active.dropdown .menu:hover {
  border-color: #005691;
  border-radius: 0;
}
