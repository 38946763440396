@use "../../../../styles/mixins.module" as m;

.wrapper {
  @include m.setStyleForFlexBox(
    column,
    flex-start,
    flex-start,
    var(--card-responsive-spacing)
  );

  .block {
    @include m.setStyleForFlexBox(
      row,
      flex-start,
      flex-start,
      var(--card-responsive-spacing)
    );

    & .dot {
      width: var(--card-responsive-spacing);
      height: var(--card-responsive-spacing);
      background-color: var(--boschBlack);

      margin-top: 0.3rem;
    }

    & span {
      max-width: 90%;
    }
  }
}
