.sc-message-list{
  overflow-y: auto;
  padding-top: 1rem;
}

.messages.sc-message {
  width: 100% !important;
  padding: 0 0.5rem 5px 1rem !important;
  display: flex;
  flex-direction: column;
  margin-top: 5px !important;
}

.messages .sc-message--content {
  width: 100%;
  display: flex;
  margin: 3px;
}

.messages .sc-message--content.received {
  align-self: flex-start;
}

.messages .sc-message--content.sent {
  justify-content: flex-end;
  align-self: flex-end;
}

.messages .sc-message--content.sent .sc-message--avatar {
  display: none;
}

.sc-message--avatar {
  background-image: url(https://d13yacurqjgara.cloudfront.net/assets/avatar-default-aa2eab7684294781f93bc99ad394a0eb3249c5768c21390163c9f55ea8ef83a4.gif);
  background-repeat: no-repeat;
  background-size: 100%;
  background-position: center;
  min-width: 30px;
  min-height: 30px;
  border-radius: 50%;
  align-self: center;
  margin-right: 15px;
}

.messages .sc-message--text {
  padding: 0.5rem;
  font-weight: 300;
  font-size: 14px;
  line-height: 1.4;
  white-space: pre-wrap;
  -webkit-font-smoothing: subpixel-antialiased;
  word-wrap: break-word;
}

.messages .sc-message--content.sent .sc-message--text {
  color: white;
  background-color: var(--boschBlue50);
  word-wrap: break-word;
  max-width: 15.375rem;
}

.messages .sc-message--content.received .sc-message--text {
  color: #263238;
  background-color: #eff1f2;
  margin-right: 40px;
  max-width: 15.375rem;
}

.sc-message--emoji {
  font-size: 40px;
}

.sc-message--file {
  background: white;
  border: solid 1px #cccdd1;
  padding: 15px 20px;
  border-radius: 5px;
  display: flex;
  font-weight: 300;
  font-size: 14px;
  line-height: 1.4;
  cursor: pointer;
  text-decoration: none;
}

.sc-message--file p {
  margin: 0px 0px 0px 10px;
  color: rgba(86, 88, 103, 0.6);
}

.sc-message--file .sc-user-input--file-icon:hover path {
  fill: rgba(86, 88, 103, 0.3);
}

.sc-author-name {
  display: flex;
}

.sc-author-name.sent {
  justify-content: flex-end;
}

.sc-author-name .sc-message--text {
  color: black;
  font-weight: bolder;
  font-size: 12px;
  padding: 0px;
}

.sc-timestamp {
  display: flex;
  height: 20px;
}

.sc-timestamp.sent {
  justify-content: flex-end;
}

.sc-timestamp .sc-message--text {
  padding: 0px;
  font-size: 11px;
  margin-bottom: 1rem;
}

.ch-timestamp {
  padding-right: 0px !important;
}

.message-time {
  margin-right: auto;
}

.sc-message > div > div[data-testid="chat-bubble"] + div {
  margin-left: unset !important;
  margin-right: unset !important;
}

@media (max-width: 450px) {
  .sc-message {
    width: 80%;
  }
}

.progress-bar {
  margin: 1px !important;
}

.ch-attachment-icon {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}

.ch-attachment-icon svg {
  position: unset !important;
}
