@use "../../../../styles/mixins.module" as m;

.container {
  @include m.setStyleForFlexBox;
  gap: var(--card-spacing-sm) !important;

  .box {
    @include m.setStyleForFlexBox(
      row,
      center,
      flex-start,
      var(--card-spacing-lg)
    );

    & .block {
      @include m.setStyleForFlexBox(column, flex-start, flex-start, 0);

      & .search_by_plate {
        @include m.setStyleForFlexBox;
        padding: var(--card-spacing-sm) 0 !important;
        background-color: var(--boschGray90) !important;
      }
    }
  }
}
