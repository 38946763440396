input {
  outline: none !important;
}

input:focus-visible {
  border: none !important;
  border-bottom: 0.0625rem solid var(--boschBlack) !important;
}

div,
.a-checkbox,
.a-button {
  -webkit-tap-highlight-color: transparent;
}
