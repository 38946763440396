.wallet_tiny {
  width: 35.75vw !important;
}

.wallet_small {
  width: 50vw !important;
}

.wallet_large {
  width: 90.5vw !important;
}

.coins {
  fill: var(--boschBlue50);
  width: 4rem;
}

.add_button {
  background: var(--boschBlue50) !important;
  color: var(--boschWhite) !important;
  font-weight: normal !important;
  width: 100% !important;
  margin: 0 !important;
  height: 3rem !important;
}

.content {
  background: var(--boschSilver) !important;
  /* border-radius: 0 !important;
    border-top: 10px solid var(--boschBlue50); */
}

.package_col {
  background: var(--boschWhite);
  padding: 0 !important;
  width: 100% !important;
}

.package_row {
  margin-left: 1rem;
  margin-right: 1rem;
  display: grid !important;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 1rem;
}

.package_row_2_col {
  margin-left: 1rem;
  margin-right: 1rem;
  display: grid !important;
  grid-template-columns: 1fr 1fr;
  gap: 1rem;
}

.package_row_1_col {
  margin-left: 1rem;
  margin-right: 1rem;
  display: grid !important;
  grid-template-columns: 1fr;
  gap: 1rem;
}

.package_data,
.package_add_btn {
  justify-content: center !important;
  padding-bottom: 0 !important;
}

.package_add_btn {
  align-self: flex-end;
}

.package_data > img {
  object-fit: contain;
  width: 50% !important;
}
.package_data_title {
  font-weight: bold;
}

.package_data_title span {
  text-align: center;
}

.package_data {
  padding-bottom: 0 !important;
  padding-top: 0 !important;
}

.service_header {
  padding: 0 !important;
  margin-left: 1rem !important;
  font-weight: bold;
}

.header {
  background: var(--boschSilver) !important;
  border: none !important;
  border-radius: 0 !important;
  border-top: var(--fs-80) solid var(--boschBlue50) !important;
}

.package_data > i {
  color: var(--boschBlue50);
  font-size: 4rem;
}

.service_credit_icon {
  padding-top: 0;
}

.content > .content_inner > .package_row > .package_col.package_item {
  width: 100% !important;
}

/* .content > .content_inner > .package_row_2_col > .package_col.package_item{
    width: 100% !important;
} */

.unitPrice {
  text-decoration: line-through;
  color: red;
}

.package_data_price {
  font-size: 0.8em;
  text-align: center;
  flex-direction: column !important;
  align-items: center !important;
}

.package_grid {
  aspect-ratio: 1;
  margin: 0 !important;
  padding: var(--card-spacing-sm) !important;
}

@media (max-width: 575px) {
  .wallet_tiny {
    width: 80vw !important;
  }

  .wallet_small,
  .wallet_large {
    width: 90vw !important;
  }

  .add_button {
    width: 100% !important;
    align-self: flex-end;
  }
  .package_row {
    grid-template-columns: 1fr;
  }
  .package_data > img {
    object-fit: contain;
    width: 40% !important;
  }
  .package_data_title {
    align-self: flex-end;
  }
  .package_add_btn {
    padding-top: 0 !important;
  }
}

@media (min-width: 992px) {
  .wallet_tiny {
    width: 23.75vw !important;
  }

  .wallet_small {
    width: 35vw !important;
  }

  .wallet_large {
    width: 57.5vw !important;
  }

  .package_data {
    padding-top: 0 !important;
  }

  .package_item {
    height: fit-content;
  }

  .package_data_price {
    margin-bottom: 1rem;
  }

  .package_add_btn {
    padding-top: 0 !important;
  }
  .add_button {
    font-size: var(--fs-80) !important;
  }
}

@media only screen and (min-width: 1200px) {
  .wallet_tiny {
    width: 13.75vw !important; /* 540px (default of sematic) -> 220px = 13.75rem */
  }

  .wallet_small {
    width: 25vw !important; /* 720px (df of semantic) -> 400px = 25vw */
  }

  .wallet_large {
    width: 47.5vw !important; /* 1080px (df of semantic) -> 760px = 47.5vw */
  }
}
