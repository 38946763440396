/*Start logout-modal*/
.logout-modal {
  display: flex;
  justify-content: center !important;
}

.logout-modal.warning > .modal {
  border-top: 6px solid var(--boschYellow85);
}

.logout-modal.error > .modal {
  border-top: 6px solid var(--boschRed50);
}

.logout-modal.success > .modal {
  border-top: 6px solid var(--boschGreen50);
}

.logout-modal > .modal > .header {
  border: 0 !important;
  padding-bottom: 0 !important;
  align-items: center;
  display: flex;
}

.logout-modal > .modal > .header > i {
  font-size: 1.25em;
}

.logout-modal > .modal > .content > .ui.grid {
  margin: 0;
}

.logout-modal > .modal > .actions {
  border: 0 !important;
  padding-top: 0 !important;
  background: transparent !important;
}

.logout-modal > .modal > .actions > button {
  min-width: 125px;
}

.login-account-dropdown > div:last-child > a {
  display: flex !important;
  align-items: center;
}

.login-account-dropdown > div:last-child > div {
  display: flex !important;
  align-items: center;
}

button.ui.button.header-icon-button
  .login-account-dropdown
  > div:last-child
  > a
  > i,
button.ui.button.header-icon-button
  .login-account-dropdown
  > div:last-child
  > a
  > svg,
button.ui.button.header-icon-button
  .login-account-dropdown
  > div:last-child
  > div
  > i {
  padding: 0 !important;
  margin-right: 0.75rem !important;
}

/*End logout-modal*/

/* 
.logOutModal {
  border-radius: 0;
  padding: 1.25rem;
  text-align: center !important;
}

.logOutModal .content, .logOutModal .actions {
  padding: 0 !important;
}

.logOutModal .content {
  padding-bottom: 1.25rem !important;
  font-size: 1.25rem !important;
}

.logOutModal > .actions {
  background: #fff !important;
  border: none !important;
}

.logOutModal > .actions .ui.button {
  font-weight: normal;
  margin: 0 1.25rem !important;
  width: 7rem;
}

.log-out-modal-container .log-out-modal-content.content,
.log-out-modal-container .log-out-modal-actions.actions {
  padding: 1rem 0 !important;
}

.log-out-modal-container .log-out-modal-actions.actions {
  display: flex !important;
  flex-direction: row !important;
  justify-content: center !important;
}

.log-out-modal-container .log-out-modal-actions {
  padding: 1rem !important;
}

@media (max-width: 567px) {
  .log-out-modal-container {
    width: 18rem !important;
  }

  .log-out-modal-container .log-out-modal-content.content {
    font-size: 1.2rem !important;
  }

  .log-out-modal-container .log-out-modal-content.content,
  .log-out-modal-container .log-out-modal-actions.actions {
    padding: .75rem 0 !important;
  }
}

@media (min-width: 768px) and (max-width: 820px) {
  .log-out-modal-container {
    width: 25rem !important;
    height: fit-content !important;
  }

  .log-out-modal-container .log-out-modal-content.content {
    font-size: 1.5rem !important;
  }

  .log-out-modal-container .log-out-modal-content.content,
  .log-out-modal-container .log-out-modal-actions.actions {
    display: flex !important;
    flex-direction: row !important;
    justify-content: center !important;
  }

  .log-out-modal-container .log-out-modal-actions.actions button {
    font-size: 1.5rem !important;
    width: 9rem !important;
  }
} */
