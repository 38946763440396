.ui.grid.container.my-hardware-container .button.my-hardware-return-button {
  background: var(--boschBlue50);
  color: #fff;
}

.ui.grid.container.my-hardware-container .button.my-hardware-repair-button {
  background: var(--boschBlue50);
  color: #fff;
}

.ui.grid.container.my-hardware-container table > tbody > tr {
  border-bottom: none;
  box-shadow: none;
}

.dimmer.test-dimmer {
  background: transparent;
}

.ui.modal > .close {
  top: 1.0535rem !important;
  right: 1rem !important;
  color: rgba(0, 0, 0, 0.87) !important;
}

.ui.positive.message {
  width: 100%;
  background-color: rgba(120, 190, 32, 0.1);
  box-shadow: none;
  color: #006249;
}

.ui.positive.message .header {
  color: #006249;
}

.ui.negative.message {
  width: 100%;
  background-color: rgba(234, 0, 22, 0.1);
  box-shadow: none;
  color: #ea0016;
}

.ui.negative.message .header {
  color: #ea0016;
}

.favourite-button > i {
  box-shadow: none !important;
  margin-left: 0.5rem !important;
}

.favourite-button:hover > i {
  font-family: Icons;
}

.myhardware-modal .common-modal-single-action > button {
  margin: 0;
}

@media only screen and (max-width: 576px) {
  .my-hardware-page-container .hardwarelist-container {
    padding-top: 0 !important;
  }

  .my-hardware-page-container
    .hardwarelist-container
    .ui.very.basic.table
    > tbody
    > tr {
    padding-top: 0 !important;
    width: 100% !important;
    display: flex !important;
    flex-direction: column !important;
    /* justify-content: space-between;
    align-items: center !important; */
    margin-bottom: 0 !important;
  }

  .my-hardware-page-container
    .hardwarelist-container
    .ui.very.basic.table
    > tbody
    > tr
    > td:first-child,
  .my-hardware-page-container
    .hardwarelist-container
    .ui.very.basic.table
    > tbody
    > tr
    > td:nth-child(2) {
    /* width: 50% !important; */
    width: 100% !important;
    padding: 0;
  }

  .my-hardware-page-container
    .hardwarelist-container
    .ui.very.basic.table
    > tbody
    > tr
    > td:nth-child(2)
    .ui.button {
    width: 100% !important;
  }

  .my-hardware-page-container
    .hardwarelist-container
    .ui.very.basic.table
    .ui.small.basic.button {
    padding: 0;
    margin-right: 0;
  }
}
