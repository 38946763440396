.container {
  position: relative;
  display: flex;
  flex-direction: column;

  width: 100%;
}

.title {
  font-size: var(--text-s);
  position: absolute;
  left: 0;
  top: 0;
  z-index: 2;
  margin: 0.25rem 0.75rem auto 1rem;
  line-height: 1.5 !important;
  pointer-events: none;
  word-break: break-all;
  -webkit-line-clamp: 1 !important;
  line-clamp: 1 !important;
}

.rd_search_input {
  z-index: 0 !important;
  border-radius: 0 !important;
  height: 3rem !important;
  width: 100% !important;
  position: relative;
}
.rd_search_input > input {
  padding: 0 1rem 0 1rem;
  height: 3rem !important;
  width: 100% !important;
  background-color: var(--boschGray90) !important;
  position: absolute;
  border-radius: 0 !important;
  border: none !important;
  border-bottom: 1px solid var(--boschBlack) !important;
}
.rd_search_input.with_title > input {
  padding-bottom: 0.3125rem !important;
  padding-top: 1.125rem !important;
}
.rd_search_input > input:focus {
  background-color: var(--boschBlue90) !important;
}
.rd_search_input > .input_buttons {
  position: absolute;
  right: 1px !important;
  display: flex;
  height: 3rem !important;
  z-index: 2;
}
.rd_search_input > .input_buttons > button {
  background-color: transparent !important;
  padding: 0.75rem !important;
  font-size: var(--fs-60) !important;
  margin: 0 !important;
  display: flex !important;
  align-items: center !important;
}
.rd_search_input > .input_buttons > button > svg {
  width: 1.5rem !important;
}

.error,
.information {
  width: 100%;
  padding: 0.25rem 1rem;
  color: var(--boschBlack) !important;
  display: flex;
}

.error {
  background-color: var(--boschRed90) !important;
}

.error > i {
  font-size: 1.5rem !important;
}

.information {
  background-color: var(--boschBlue80) !important;
}
