@use "../../styles/dimension.module" as d;

.container,
.container.m {
  :global(.a-box--modal) {
    z-index: 9999;
    overflow-y: auto;
    flex-direction: row;
    align-items: safe center;

    & > :global(.a-box) {
      background-color: var(--boschWhite);
      max-width: d.$ads_dialog_max_width_m;
      min-width: d.$ads_dialog_min_width_m;
    }
  }
}

.container.l {
  :global(.a-box--modal) {
    & > :global(.a-box) {
      background-color: var(--boschWhite);
      max-width: d.$ads_dialog_max_width_l;
      min-width: d.$ads_dialog_min_width_l;
    }
  }
}
