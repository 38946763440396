@use "../../../styles/responsive.module" as r;

.container {
  height: 3rem;
  width: 100%;
  border-bottom: 1px solid var(--boschGray55);
  background-color: var(--boschWhite);
  padding-left: 2rem;

  .sc-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-inline: 1rem;
    padding-block: 0.5rem;
    height: 100%;
    align-items: center;

    p {
      padding: 0;
      margin: 0;
      font-weight: 600;
      font-size: 1rem;
    }

    & :global(.a-toggle) {
      position: relative !important;
      display: flex !important;
      align-items: center !important;
      gap: 0.5rem !important;
    }

    & :global(.a-toggle) input[type="checkbox"] {
      position: absolute !important;
      appearance: none !important;
      outline: 0 !important;
    }

    &
      :global(.a-toggle)
      input[type="checkbox"]:checked
      + :global(.a-toggle__box) {
      background-color: var(--major-accent__enabled__fill__default);
    }

    & :global(.a-toggle__box) {
      position: relative;
      cursor: pointer;
      background: var(--small__enabled__fill__default);
      border-radius: 0.75rem;
      height: 1.5rem;
      width: 3rem;
    }

    &
      :global(.a-toggle)
      input[type="checkbox"]:checked
      + :global(.a-toggle__box)::after {
      background-color: var(--major-accent__enabled__front__default);
      left: 1.875rem;
    }

    & :global(.a-toggle__box)::after {
      background-color: var(--small__enabled__front__default);
      border-radius: 0.625rem;
      content: "";
      height: 0.75rem;
      left: 0.375rem;
      position: absolute;
      top: 0.375rem;
      transition: left 0.2s ease-out, background-color 0.1s ease-out;
      width: 0.75rem;
    }

    .icon-function {
      display: flex;
      flex-direction: row;
      gap: 1rem;
      > i {
        cursor: pointer;
      }
    }
  }
}

@include r.laptop_s_screen_and_up {
  .container {
    padding-left: 0;
  }
}
