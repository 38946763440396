.wallet_balance_wrapper,
.wallet_balance_actions {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  gap: var(--card-spacing-sm) !important;
}

.credit_value {
  color: var(--boschGreen50) !important;
}
