/*Start account-in-review-cmd3-modal*/
.account-in-review-cmd3-modal {
  display: flex;
  justify-content: center !important;
}

.account-in-review-cmd3-modal.warning > .modal {
  border-top: 6px solid var(--boschYellow85);
}

.account-in-review-cmd3-modal.error > .modal {
  border-top: 6px solid var(--boschRed50);
}

.account-in-review-cmd3-modal.success > .modal {
  border-top: 6px solid var(--boschGreen50);
}

.account-in-review-cmd3-modal > .modal > .header {
  border: 0 !important;
  padding-bottom: 0 !important;
  /* align-items: center; */
  display: flex;
  font-size: 1.25rem !important;
}

.account-in-review-cmd3-modal > .modal > .header > i {
  font-size: 2rem !important;
  font-weight: normal !important;
  margin-top: -3px;
}

.account-in-review-cmd3-modal > .modal > .content > .ui.grid {
  margin: 0;
  font-size: 0.9rem;
}

.account-in-review-cmd3-modal > .modal > .content > .ui.grid > .row {
  flex-direction: column;
}

.account-in-review-cmd3-modal > .modal > .content > .ui.grid .activation-link {
  display: flex;
  flex-direction: column;
  margin-bottom: 1.5rem;
}

.account-in-review-cmd3-modal
  > .modal
  > .content
  > .ui.grid
  .activation-link
  a {
  text-decoration: underline;
  font-weight: 900;
}

.account-in-review-cmd3-modal
  > .modal
  > .content
  > .ui.grid
  small.activation-link-notes {
  font-size: 80%;
}

.account-in-review-cmd3-modal > .modal > .content > .ui.grid .description-text {
  font-size: 95%;
}

.account-in-review-cmd3-modal
  > .modal
  > .content
  > .ui.grid
  .description-text
  > a {
  text-decoration: underline;
}

.account-in-review-cmd3-modal > .modal > .actions {
  border: 0 !important;
  /* padding-top: 0 !important; */
  /* background: transparent !important; */
}

.account-in-review-cmd3-modal > .modal > .actions > button {
  min-width: 125px;
  font-weight: normal !important;
}
/*End account-in-review-cmd3-modal*/
