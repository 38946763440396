.rd_badge {
  display: flex !important;
  justify-content: center;
  align-items: center;

  min-width: 1rem !important;
  border-radius: 0.5rem !important;
  padding: 0 !important;
  margin: 0 !important;
}

.badge_content {
  font-size: var(--text-s) !important;
  font-weight: normal !important;

  margin: 0 0.25rem !important;
}

.absolute_badge {
  position: absolute;

  top: 0.5rem;
  left: 50%;
  z-index: 10 !important;
}

.default {
  background-color: var(--primary-major-signal-neutral-default-bgr) !important;
}

.default > .badge_content {
  color: var(--primary-major-signal-neutral-default-fgr) !important;
}

.success {
  background-color: var(--primary-major-signal-success-default-bgr) !important;
}

.success > .badge_content {
  color: var(--primary-major-signal-success-default-fgr) !important;
}

.warning {
  background-color: var(--primary-major-signal-warning-default-bgr) !important;
}

.warning > .badge_content {
  color: var(--primary-major-signal-warning-default-fgr) !important;
}

.error {
  background-color: var(--primary-major-signal-error-default-bgr) !important;
}

.error > .badge_content {
  color: var(--primary-major-signal-error-default-fgr) !important;
}
