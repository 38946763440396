@use "../../../styles/mixins.module" as m;

.container {
  @include m.setStyleForFlexBox(row, flex-start);
  padding: var(--card-spacing-sm) 0;

  & .title {
    font-weight: bold !important;
    color: var(--boschBlue50) !important;
  }
}
