@use "../../../styles/mixins.module" as m;

.container {
  @include m.setStyleForFlexBox(row, flex-start);
  padding-bottom: var(--card-responsive-spacing);

  & .title {
    font-weight: bold !important;
  }
}
