.breadcrumb {
  font-size: var(--text-s) !important;
  display: flex !important;
  align-items: center !important;
}

.breadcrumb > a {
  color: var(--boschBlack) !important;
}

@media screen and (max-width: 767px) {
  .breadcrumb {
    display: none !important;
  }
}
