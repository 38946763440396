.container {
  font-size: var(--text-s) !important;

  & .link {
    cursor: pointer;

    & .icon {
      font-size: 1.5rem !important;
    }

    & a,
    & span {
      display: flex;
      align-items: center;
    }
  }

  & > li {
    display: flex;
    align-items: center;

    &:last-child {
      cursor: text;
      pointer-events: none;
    }

    & > a {
      color: var(--minor-accent__enabled__front__default) !important;
    }
  }
}
