.add_ons_card {
  width: 100% !important;
  box-shadow: none !important;
  border-radius: 0 !important;
}
.add_ons_card > .content {
  background-color: var(--boschGray95) !important;
}
.grid {
  margin: 0 !important;
  gap: var(--card-spacing-sm) !important;
}
.row {
  padding: 0 !important;
}
.column {
  padding: 0 !important;
}
.title {
  font-size: 1rem !important;
}
.dropdown {
  border-radius: 0 !important;
  font-weight: normal !important;
  padding: var(--card-spacing-xs) !important;
  padding-top: 0.5rem !important;
  padding-bottom: 0.5rem !important;
  margin-top: 0.25rem !important;
  margin-right: 0 !important;
  font-size: var(--fs-77) !important;
  display: flex !important;
  justify-content: space-between;
}
.dropdown_icon {
  margin-left: 0.5rem;
}
.dropdown_icon > i {
  margin-right: 0 !important;
}
.order_summary_contents {
  font-size: var(--fs-80);
  /* margin-bottom: 1rem; */
}
.content_row {
  display: grid;
  grid-template-columns: 2.5fr 1fr;
  border-bottom: 1px solid var(--boschGray20-gradient10) !important;
  margin-bottom: var(--card-spacing-xs) !important;
  padding-bottom: var(--card-spacing-xs) !important;
  gap: 0.5rem;
}
.content_row.no_border {
  border-bottom: none !important;
  padding-bottom: 0 !important;
}
.order_summary_contents > .content_row:last-child {
  border-bottom: none !important;
  padding-bottom: 0 !important;
  margin-bottom: 0 !important;
}
.field_label {
  display: flex;
  align-items: center;
}
.add_on_status {
  text-align: right;
}
img.add_on_icon {
  width: 24px;
  margin-right: 0.25rem;
  margin-left: -5px;
}
i.add_on_icon {
  font-size: 1.25rem;
  margin-left: -5px;
}
i.help_icon {
  color: var(--boschBlue50);
  font-size: 1rem;
  margin-left: 0.25rem;
}
i.help_icon:hover {
  cursor: pointer;
}
.field_value {
  /* display: flex;
    justify-content: flex-end; */
  text-align: right;
  /* padding-right: 1rem !important; */
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-start;
  position: relative;
}
.field_value > a {
  display: flex;
  align-items: center;
  width: fit-content;
}
.total_amount {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}
.total_amount > span {
  font-size: var(--fs-100) !important;
  line-height: 1;
}
.remove_button {
  position: absolute;
  right: -1.4375rem;
  /* top: 2px; */
  font-size: 1.15rem;
  color: var(--boschRed50);
}
.quantity_input {
  width: 3.75rem;
}
.quantity_input > input {
  border-radius: 0 !important;
  /* text-align: center; */
  /* background-color: var(--boschGray100) !important; */
}
.quantity_input > input[type="number"]::-webkit-inner-spin-button,
.quantity_input > input[type="number"]::-webkit-outer-spin-button {
  opacity: 1;
}
button.primary_button,
button.secondary_button,
button.default_button,
button.success_button {
  margin: 0 !important;
  font-size: 1rem !important;
  padding: 1rem 2rem !important;
  font-weight: bold !important;
  display: flex;
}
button.light_button {
  font-weight: normal !important;
}
button.primary_button {
  background-color: var(--boschBlue50) !important;
  color: var(--boschWhite) !important;
}
.check_out_button {
  width: 100%;
}
.top_button_row {
  margin-bottom: var(--card-spacing);
}
/*.bottom_button_row{
    margin-top: .5rem;
}*/
.voucher_row > a {
  /* margin-top: var(--card-spacing);
    margin-bottom: var(--card-spacing-xs); */
  /* text-align: right; */
  font-size: var(--fs-75);
}
.card_images {
  display: grid;
  gap: 10px;
  grid-template-columns: repeat(4, 45px);
  align-items: center;
}
.card_images > span {
  padding: 0.5px 10px;
  border: 1px solid var(--boschGray20-gradient10);
  border-radius: 0.24rem;
  height: 100%;
  background-color: var(--boschWhite) !important;
}

.bosch_account_icon {
  width: 5rem;
}

.bosch_account_icon > img {
  width: 100%;
  height: 100%;
}

.order_item {
  display: flex;
  align-items: flex-start;
  gap: 5px;
  justify-content: space-between;
}
.order_item_name {
  display: flex;
  align-items: flex-start;
  gap: 5px;
}
.order_item_name > i {
  font-size: 1.5rem;
}
.order_item img.addon_icon {
  width: 22px;
}
.add_on_list {
  list-style: none;
  margin: 0;
  padding-left: 0;
  font-size: var(--fs-80);
}
.add_on_list > li {
  line-height: 1.5;
  font-size: 0.625rem;
  margin-bottom: 0 !important;
}
.discount_price {
  text-decoration: line-through;
  color: var(--boschRed50);
}
.voucher_wrapper {
  border-top: 1px solid var(--boschGray20-gradient10) !important;
  border-bottom: 1px solid var(--boschGray20-gradient10) !important;
  padding-top: 1rem !important;
}
.voucher_applied {
  display: flex !important;
  justify-content: space-between;
  align-items: center;
  margin: 0 0 var(--card-spacing-sm) 0 !important;
}
.voucher_applied > div {
  margin: 0 !important;
}
.voucher_applied > .voucher_row.editable {
  display: flex;
  margin-right: -27px !important;
  /* gap: 10px; */
}
.voucher_applied > .voucher_row.editable > .voucher_code {
  margin-right: 10px;
  display: inline-block;
}
.voucher_applied > .voucher_row > .remove_voucher {
  color: var(--boschRed50) !important;
}
.voucher_applied > .voucher_row > a {
  font-size: 1.25rem !important;
}
.no_border_bottom {
  border-bottom: 0 !important;
}
@media (max-width: 767px) {
  .add_ons_card {
    box-shadow: 0 0 3px #00000040 !important;
  }
  .add_ons_card > .content {
    background-color: var(--boschWhite) !important;
  }
}
