@import '../../../styles/mixins.module';

.dialog-modal {
  outline: none !important;
  > div:first-child {
    background-color: white !important;
    > div:first-child,
    > div:nth-child(2),
    > hr {
      display: none !important;
    }
    > div:last-child {
      padding: 0 !important;
      > div:first-child {
        margin: 0 !important;
      }
    }
  }

  .emoji-container {
    .emoji-block {
      border: none !important;
      height: 300px !important;
      width: 600px !important;
      > div:nth-child(2) {
        ul > li::before {
          display: none !important;
        }
        ul > li > h2 {
          --font-size: 1rem !important;
          --line-height: 1.3 !important;
          font-size: 1rem !important;
          line-height: 1.3 !important;
        }
      }
      > div:last-child {
        display: none !important;
      }
    }
  }
}
