@use "../../styles/responsive.module" as r;
@use "../../styles/zIndex.module" as z;

.container {
  display: flex;
  flex-direction: column;

  width: 100%;

  & .maintenance_block {
    position: fixed;
    z-index: z.$maintenance_banner_z_idx;

    top: 0;
    left: 0;
  }

  & .wrapper {
    & .minimal_header {
      z-index: z.$minimal_header_z_index;

      & :global(.m-side-navigation__menuItem) {
        visibility: hidden;
      }

      &.open {
        & :global(.m-side-navigation__menuItem),
        & :global(.a-menu-item) {
          visibility: visible;
        }
      }
    }

    & .main_content {
      display: flex;
      padding-top: calc(3rem + 6px);

      width: 100%;
      min-height: calc(100vh - 2.25rem);

      & .route_content {
        width: 100%;
        padding: var(--card-responsive-spacing);
        display: flex;
        flex-direction: column;
      }
    }
  }
}

@include r.laptop_s_screen_and_up {
  .container {
    & .wrapper {
      & .minimal_header {
        & :global(.m-side-navigation__menuItem),
        & :global(.a-menu-item) {
          visibility: visible;
        }

        &.open {
          & :global(.o-minimal-header__top) {
            padding-left: 16rem;
          }

          &.header_with_main_content_pinned {
            & :global(.o-minimal-header__top) {
              padding-left: 3rem;
            }
          }
        }
      }

      & .main_content,
      & .footer_wrapper {
        padding-left: 3rem;
        transition: 0.5s;
      }

      & .main_content {
        &.minimal {
          padding-left: 19rem;
        }

        & .route_content {
          padding: var(--card-spacing) var(--card-spacing-lg);
        }
      }

      & .footer_wrapper.minimal {
        padding-left: 19rem;
      }
    }
  }
}
