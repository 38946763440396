.ui.modal {
  border-radius: 0;
}

div.common-modal-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 20px 30px;
}

div.common-modal-header .common-modal-header-text {
  font-size: 1.5rem;
  font-weight: bold;
}

div.common-modal-header .close-button-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

div.common-modal-header .close-button-container .close-button {
  width: 30px;
  height: 30px;
  background-color: transparent;
  color: #ffffff;
  opacity: 0.8;
}

div.common-modal-header .close-button-container .close-button:hover {
  cursor: pointer;
}

div.common-modal-content {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 20px 30px;
  max-height: 70vh;
  overflow: auto;
}

.common-modal-content-section {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

div.common-mini-modal-content {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 15px;
  max-height: 70vh;
  overflow: auto;
}

.common-mini-modal-content-section {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.form-element {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

div.common-modal-single-action {
  display: flex;
  flex-direction: row;
  padding: 15px;
  margin-top: 10px;
}

div.common-modal-single-action .ui.button {
  width: 100%;
}

div.common-modal-multiple-actions {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  padding: 15px;
}

div.common-modal-multiple-actions .ui.button {
  margin-left: 10px;
}
