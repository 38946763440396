.ui.form textarea {
  background-color: #efeff0;
  border-radius: 0;
  color: #000000;
}

.ui.form textarea:focus {
  border-color: #005691;
  border-radius: 0;
}
