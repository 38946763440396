.sc-user-input {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: #efeff0;
  padding: 0.5rem;
  gap: 1rem;
}

.emojiModalWidget {
  position: absolute;
  bottom: 35px;
  width: fit-content;
}

.sc-send-input > input {
  padding: 1rem 1rem 1rem 0 !important;
}

.sc-user-input--text {
  width: 100% !important;
  resize: none;
  border: none;
  outline: none;
  box-sizing: border-box;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.33;
  white-space: pre-wrap;
  word-wrap: break-word;
  color: black !important;
  -webkit-font-smoothing: antialiased;
  bottom: 0;
  /* overflow-x: hidden;
  overflow-y: auto; */
  cursor: text;
}

.sc-user-input--text > input {
  background: transparent !important;
  border: none !important;
}

.sc-user-input--text:empty:before {
  content: attr(placeholder);
  display: block; /* For Firefox */
  color: black;
  outline: none;
}

.sc-user-input--buttons {
  width: 50px;
  position: absolute;
  right: 0px;
  height: 100%;
  display: flex;
  justify-content: flex-end;
  background-color: lightgray;
}

.sc-user-input--button:first-of-type {
  width: 40px;
}

.sc-user-input--button {
  width: 30px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.sc-user-input--button button {
  cursor: pointer;
}

.sc-user-input--buttons input[type='file'] {
  display: none;
}

.sc-user-input--picker-wrapper {
  display: flex;
  flex-direction: column;
}

.sc-user-input.active {
  background: #d1e4ff !important;
  box-shadow: 0px -5px 20px 0px rgba(150, 165, 190, 0.2) !important;
}

.sc-user-input--file-icon,
.sc-user-input--send-icon {
  height: 20px;
  width: 20px;
  cursor: pointer;
  align-self: center;
  outline: none;
}

.sc-user-input--file-icon path,
.sc-user-input--send-icon path {
  fill: rgba(86, 88, 103, 0.3);
}

.sc-user-input--file-icon:hover path,
.sc-user-input--send-icon:hover path {
  fill: rgba(86, 88, 103, 1);
}

.sc-user-input--emoji-icon-wrapper,
.sc-user-input--send-icon-wrapper,
.sc-user-input--file-icon-wrapper {
  background: none;
  border: none;
  padding: 2px;
  margin: 0px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.sc-user-input--send-icon-wrapper,
.sc-user-input--file-icon-wrapper {
  flex-direction: row;
}

.sc-user-input--emoji-icon-wrapper:focus {
  outline: none;
}

.sc-user-input--emoji-icon {
  height: 18px;
  cursor: pointer;
  align-self: center;
}

.sc-user-input--emoji-icon path,
.sc-user-input--emoji-icon circle {
  fill: rgba(86, 88, 103, 0.3);
}

.sc-user-input--emoji-icon-wrapper:focus .sc-user-input--emoji-icon path,
.sc-user-input--emoji-icon-wrapper:focus .sc-user-input--emoji-icon circle,
.sc-user-input--emoji-icon.active path,
.sc-user-input--emoji-icon.active circle,
.sc-user-input--emoji-icon:hover path,
.sc-user-input--emoji-icon:hover circle {
  fill: rgba(86, 88, 103, 1);
}

.sc-send-image {
  max-width: 70%;
}

.userInput--button {
  position: absolute;
  right: 1rem;
  background: none !important;
  margin: 0 !important;
  width: 40px !important;
}

.upload-object-preview {
  margin: 0 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.upload-object-preview > .preview--text {
  width: 80% !important;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.upload-object-preview > button {
  background: none !important;
}

.sc-user-input .input-component {
  position: relative;
}

.sc-user-input .input-component .text_area {
  max-width: 100%;
  min-width: 100%;
  background-color: var(--boschWhite);
  max-height: 100px;
  overflow: auto;
  resize: none;
  height: 40px; /* Set your default height */
  padding: 10px;
  border: none;
}

.sc-user-input .input-component .text_area:active,
.sc-user-input .input-component .text_area:focus {
  outline: none;
  border-bottom: 2px solid var(--boschBlue50);
}

.sc-user-input .input-text-count p {
  font-size: 0.7rem !important;
  margin: 0 !important;
  text-align: end !important;
}

.sc-user-input .input-text-count.limited p {
  color: var(--boschRed50);
}

.sc-user-input .input-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.sc-user-input .input-container .actions-left{
  display: flex;
  flex-direction: row;
  gap: 0.75rem;
  position: relative;
}

.sc-user-input .input-container .actions-left i{
  cursor: pointer;
}
