.ui.secondary.pointing.menu {
  display: flex;
  flex-direction: row;
  justify-content: center;
  border-bottom: 1px solid #e0e2e5;
}

.ui.secondary.pointing.menu .item {
  margin-left: 30px;
  margin-right: 30px;
  padding-left: 0;
  padding-right: 0;
}

.ui.secondary.pointing.menu .active.item {
  border-color: #007bc0;
  color: #007bc0;
  font-weight: normal;
}

.ui.secondary.pointing.menu .active.item:hover {
  border-color: #005691;
  color: #005691;
}

.ui.segment.active.tab {
  border: none;
  box-shadow: none;
}

@media only screen and (max-width: 550px) {
  .ui.secondary.pointing.menu {
    display: contents;
    flex-direction: row;
    justify-content: center;
    border-bottom: 1px solid #e0e2e5;
  }
  .ui.secondary.pointing.menu .item {
    margin-left: 0px;
    margin-right: 30px;
    padding-left: 0;
    padding-right: 0;
  }
  
  .ui.secondary.pointing.menu .active.item {
    border-color: #007bc0;
    color: #007bc0;
    font-weight: normal;
  }
  
  .ui.secondary.pointing.menu .active.item:hover {
    border-color: #005691;
    color: #005691;
  }
  
  .ui.segment.active.tab {
    margin-top: 10rem;
    border: none;
    box-shadow: none;
  }
}