@use "../../../styles/dimension.module" as d;
@use "../../../styles/responsive.module" as r;

.header_bar {
  max-width: 100%;
  width: 100%;
  background-color: white;
}

.header_bar > div {
  max-width: d.$base_screen_max_width !important;
  margin: 0 auto !important;
}

.inner_header_bar {
  width: 100% !important;
  max-width: d.$base_screen_max_width;
  margin: auto !important;
  padding: 0 !important;
  background: var(--boschWhite);
}

.inner_header_bar > div {
  background: var(--boschGray95);
}

.inner_header_bar div {
  max-width: d.$base_screen_max_width;
  margin-left: 0 !important;
  align-items: center;
}

.connecter_status {
  padding-right: 0 !important;
}

.connecter_status div {
  width: 100%;
}

.header_content {
  padding-right: 0 !important;
  color: var(--boschBlack);
  font-size: var(--text-l);
  font-weight: bold;
  line-height: 1.28571429em;
}

@media (max-width: 767.5px) {
  .connecter_status {
    flex-direction: row !important;
  }

  /* CUSTOM RD CONVERSION ON MOBILE FOR WALLET PAGE */
  .wallet_inner_header_bar > div {
    flex-direction: column !important;
    padding-top: var(--card-responsive-spacing) !important;
    padding-bottom: var(--card-responsive-spacing) !important;
  }

  .wallet_inner_header_bar > div > div:first-child {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;

    width: 100%;
    margin-bottom: 0 !important;
    min-height: fit-content !important;
  }

  .wallet_inner_header_bar > div > div:nth-child(2) {
    width: 100% !important;
  }

  .wallet_inner_header_bar > div > div:nth-child(2) > div {
    justify-content: space-between;
  }
}

@include r.bosch_ads_625x_screen_and_up {
  .header_bar > div {
    max-width: d.$bosch_ads_625x_screen_max_width !important;
  }

  .inner_header_bar {
    max-width: d.$bosch_ads_625x_screen_max_width;
  }

  .inner_header_bar div {
    max-width: d.$bosch_ads_625x_screen_max_width;
  }
}

@include r.desktop_l_screen_and_up {
  .header_bar > div {
    max-width: d.$base_screen_max_width !important;
  }

  .inner_header_bar {
    max-width: d.$base_screen_max_width;
  }

  .inner_header_bar div {
    max-width: d.$base_screen_max_width;
  }
}
