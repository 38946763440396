.ui.checkbox .box:before,
.ui.checkbox label:before {
  background-color: #dfdfe0;
  border-color: transparent;
  border-radius: 0;
  transform: scale(1.4);
}

.ui.checkbox input:checked ~ .box:before,
.ui.checkbox input:checked ~ label:before {
  background-color: #007BC0;
  border-color: transparent;
}

.ui.checkbox input:focus ~ .box:before,
.ui.checkbox input:focus ~ label:before {
  background-color: #dfdfe0;
  border-color: transparent;
}

.ui.checkbox input:checked:focus ~ .box:before,
.ui.checkbox input:checked:focus ~ label:before,
.ui.checkbox input:not([type="radio"]):indeterminate:focus ~ .box:before,
.ui.checkbox input:not([type="radio"]):indeterminate:focus ~ label:before {
  background-color: #005691;
  border-color: transparent;
}

.ui.checkbox input:checked ~ .box:after,
.ui.checkbox input:checked ~ label:after {
  color: #ffffff;  
}

.ui.checkbox.checked.thin-checkbox > label:after {
  -webkit-text-stroke: 1px #007BC0;
  transform: scale(1.5);
}

.ui.checkbox input:checked:focus ~ .box:after,
.ui.checkbox input:checked:focus ~ label:after,
.ui.checkbox input:not([type="radio"]):indeterminate:focus ~ .box:after,
.ui.checkbox input:not([type="radio"]):indeterminate:focus ~ label:after {
  color: #ffffff;
}
