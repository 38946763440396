@use "../../../../styles/responsive.module" as r;
@use "../../../../styles/mixins.module" as m;

.container {
  display: flex;
  justify-content: center;
  align-items: safe center;
  cursor: pointer;
  overflow-y: auto;

  z-index: 99999;

  & .wrapper {
    @include m.setStyleForFlexBox(column, space-between);
    position: relative;
    background-color: var(--boschWhite);
    overflow: hidden;
    gap: var(--card-spacing-lg);
    padding: var(--card-spacing-sm);

    width: 80vw;
    max-height: 80vh;

    & .title {
      @include m.setStyleForFlexBox(column);

      & > p {
        margin: 0 !important;
      }

      & > p:first-child {
        font-size: var(--text-xl);
        font-weight: bold;
      }
    }

    & .main_content {
      @include m.setStyleForFlexBox(row, center, stretch);
      gap: var(--card-spacing-sm) !important;
      padding: var(--card-spacing-sm) 0;
      flex: 1 1 0%;

      & .camera_input_panel {
        overflow-y: hidden;
        width: 100%;

        & .camera_selection,
        & .camera_viewport {
          width: 100%;
        }

        & .camera_selection {
          margin-bottom: var(--card-spacing-sm);
        }

        & .camera_viewport {
          width: max-content;
          height: max-content;

          max-width: 40rem;
          max-height: 30rem;
        }
      }
    }

    & .bottom {
      @include m.setStyleForFlexBox(
        row,
        space-between,
        center,
        var(--card-spacing-sm)
      );

      & .bottom_right {
        @include m.setStyleForFlexBox(
          row,
          flex-end,
          center,
          var(--card-spacing-sm)
        );
        width: max-content !important;
      }
    }

    & .camera_detecting {
      & > p {
        font-size: var(--text-l);
        margin: 0 !important;
      }
    }
  }
}

@include r.tablet_screen_and_up {
  .container {
    & .wrapper {
      width: max-content;
      padding: var(--card-spacing-lg);

      & .main_content {
        & .camera_viewport {
          width: 38rem;
        }
      }
    }
  }
}
