@import "../../../styles/mixins.module";

.container {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;

  .chat-area {
    flex: 1;
    position: relative;
    height: calc(100% - 3rem);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-color: var(--boschWhite);
    width: 100%;

    .loadingPanel {
      @include flex-center;
      position: absolute;
      height: 100%;
      width: 100%;
      background-color: var(--boschGray55) !important;
    }
  }
}
