@use "../../styles/responsive.module" as r;

/*Start welcome_cmd3_modal*/
.welcome_cmd3_modal {
  display: flex;
  justify-content: center !important;
}

.welcome_cmd3_modal.warning > .modal {
  border-top: 6px solid var(--boschYellow85);
}

.welcome_cmd3_modal.error > .modal {
  border-top: 6px solid var(--boschRed50);
}

.welcome_cmd3_modal.success > .modal {
  border-top: 6px solid var(--boschGreen50);
}

.welcome_cmd3_modal > .modal > .header {
  border: 0 !important;
  padding-bottom: 0 !important;
  /* align-items: center; */
  display: flex;
  font-size: 1.25rem !important;
}

.welcome_cmd3_modal > .modal > .header > i {
  font-size: 2rem;
  font-weight: bold !important;
  margin-top: -3px;
}

.welcome_cmd3_modal > .modal > .content > .ui.grid {
  margin: 0;
  font-size: 0.9rem;
}

.welcome_cmd3_modal > .modal > .content > .ui.grid > .row {
  flex-direction: column;
}

.welcome_cmd3_modal > .modal > .content > .ui.grid .activation_link {
  display: flex;
  flex-direction: column;
  margin-bottom: 1.5rem;
}

.welcome_cmd3_modal > .modal > .content > .ui.grid .activation_link a {
  text-decoration: underline;
}

.welcome_cmd3_modal > .modal > .content > .ui.grid small.activation_link_notes {
  font-size: 80%;
}

.welcome_cmd3_modal > .modal > .content > .ui.grid .description_text {
  font-size: 95%;
}

.welcome_cmd3_modal > .modal > .content > .ui.grid .description_text > a {
  text-decoration: underline;
}

.welcome_cmd3_modal > .modal > .actions {
  border: 0 !important;
  /* padding-top: 0 !important; */
  /* background: transparent !important; */
}

.welcome_cmd3_modal > .modal > .actions > button {
  min-width: 125px;
  font-weight: normal !important;
}
/*End welcome_cmd3_modal*/

@include r.desktop_m_screen_and_up {
  .modal {
    width: 35.625rem !important; // == 570px
  }
}
