.maintenance_message {
  color: var(--boschBlue50);
  padding-left: 5rem;
  padding-right: 5rem;
  font-size: medium;
  font-weight: bold;
  margin-top: 0.5rem !important;
}
.info_message {
  margin-bottom: -0.5rem;
}
.maintenance_image {
  display: flex;
  flex-flow: column;
}
.maintenance_image > svg {
  height: 580px;
}
