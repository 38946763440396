@use "../../styles/responsive.module" as r;
@use "../../styles/mixins.module" as m;

.menu-wrapper {
  position: relative;
  height: 0px;
  margin: 0px !important;
}

.menu-wrapper > .top-menu {
  z-index: 99 !important;
  background-color: #fff;
}

.menu-wrapper > .top-menu.visible {
  box-shadow: 0 -16px 0 #fff, 0 0 16px rgb(0 0 0 / 25%);
}

.supergraphic {
  display: flex;
  width: 100%;
  height: var(--super-graphic-height);
  z-index: 100;
}
/* header box container */
.header-large-box {
  height: 6rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.landing-page-title {
  margin-top: 0 !important;
  font-weight: bold !important;
  font-size: larger;
}

/* header buttons (contact - search - menu) */
.ui.grid.header-buttons {
  height: 3rem;
  margin: 0rem;
}
.ui.grid.header-buttons > .row {
  padding: 0rem;
}
.ui.grid.header-buttons button.ui.button {
  font-size: 16px;
  line-height: 24px;
  color: #000000 !important;
  font-family: "boschsans", "Helvetica Neue", Helvetica, Arial, sans-serif;
}
.ui.grid.header-buttons .ui.basic.button:hover {
  color: rgba(0, 0, 0, 1) !important;
}
button.ui.button.header-icon-button:active {
  background: transparent !important;
}

button.ui.button.header-icon-button {
  box-shadow: none !important;
  margin: 0rem;
  align-items: center;
  padding: 0 !important;
  display: flex;
  border: none !important;
}

button.ui.button.header-icon-button i.icon {
  font-size: 1.5rem;
  position: relative;
  display: inline-block;
  width: 1em;
  height: 1em;
  box-sizing: initial;
  padding: 0.75rem 0rem 0.75rem 0.875rem;
  margin: 0 !important;
}
button.header-icon-button div.ui.dropdown.login-account-dropdown {
  display: flex;
  flex-direction: row-reverse;
  -ms-flex-direction: row-reverse;
  align-items: center;
  font-weight: normal !important;
}
button.header-icon-button div.ui.dropdown.login-account-dropdown i.icon {
  vertical-align: sub;
  margin: 0.75rem 0.875rem;
}

/* breadcumb box */
.breadcumb-box-account-cart {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.breadcumb-box-account-cart-dashboard-full-width {
  padding: 0 !important;
  background-color: var(--boschWhite);
}

.breadcumb-box-account-cart .ui.grid {
  margin: 0;
}

.breadcumb-box-account-cart-dashboard > .ui.grid > .row,
.breadcumb-box-account-cart-dashboard-full-width > .ui.grid > .row {
  padding-top: 0 !important;
}

.breadcumb-box-account-cart button.ui.button {
  box-shadow: none;
  font-size: 12px;
  line-height: 18px;
  color: #000 !important;
}

div.header-menu-popup-content {
  background: #fff;
  width: 100%;
  box-shadow: 0 -16px 0 #fff, 0 0 16px rgb(0 0 0 / 25%);
  padding-bottom: 2rem;
}
div.ui.popup.header-menu-popup::before {
  display: none;
}

/*breadcrumb*/
.breadcumb-box-account-cart .ui.breadcrumb a.section {
  font-size: 0.75rem;
  color: #bfc0c2;
}
.breadcumb-box-account-cart .ui.breadcrumb a.section:last-child {
  color: #000;
}
.breadcumb-box-account-cart .ui.breadcrumb i.icon {
  color: #000;
  vertical-align: middle;
  margin: 0 0.5rem;
}

/*menu popup*/
div.header-search-popup-content {
  width: 100%;
}
div.header-search-popup-content > div:first-child {
  display: none;
}

.hiden_element {
  display: none !important;
}

.language-text {
  position: absolute;
  font-size: 0.6rem;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) !important;
  -ms-transform: translate(-50%, -50%) !important;
}

.language-icon {
  padding: 0 !important;
}

.header-language-icon {
  position: relative !important;
}

.header-cart-cdm-3 i {
  padding-right: 1.375rem !important;
}

.maintenance_message .icon {
  font-size: var(--fs-60) !important;
}

.maintenance_message {
  margin: 0 auto !important;
  padding: var(--card-responsive-spacing) var(--card-spacing) !important;
  background: var(--boschBlue85) !important;
  color: var(--boschBlack) !important;
  border-radius: unset !important;
  min-height: 2rem;
  box-shadow: none !important;
}

.maintenance_detail > .content {
  display: flex !important;
  align-items: flex-start;
  padding-right: var(--card-spacing);
}

.maintenance_detail > .content > i:first-child {
  margin-right: var(--card-responsive-spacing);
}

.maintenance_detail > .content p {
  margin: 0;
  margin-top: 2px;
  opacity: 1;
  font-size: var(--fs-80);
}

.maintenance_detail > .content > i:last-child {
  margin: 0;
  position: absolute;
  right: var(--card-spacing);
  margin-right: -5px;
}

.maintenance_detail > .content > i:last-child:hover {
  cursor: pointer;
}

.maintenance_block {
  background: var(--boschBlue85) !important;
  margin-bottom: 0;
  width: 100%;
}

.ui-header-wrapper {
  background-color: var(--boschWhite);
}

.sticky-component-wrapper {
  width: 100%;
  background-color: var(--boschWhite);
}

.sticky-mode-on {
  position: fixed !important;
  z-index: var(--full-menu-header-z-index);

  top: 0;
  left: 0;
}

.sticky-mode-on.page-header-top-wrapper {
  z-index: calc(var(--full-menu-header-z-index) + 1);
}

.language-selection-btn {
  margin-top: 0.1875rem;
}

.search-field-container {
  position: absolute;
  z-index: calc(var(--full-menu-header-z-index) + 1);

  left: 0;
  width: 100%;
  opacity: 0;
}

@media only screen and (min-width: 768px) {
  button.ui.button.header-icon-button i.icon {
    padding-right: 0.5rem;
  }
  button.ui.button.header-icon-button:last-child i.icon {
    padding-right: 0.5rem;
  }
}

@media only screen and (max-width: 767px) {
  .landing-page-title {
    font-size: 0.8rem !important;
    margin-top: 0 !important;
  }

  .header-large-box {
    padding: 0rem;
    height: 3rem;
  }

  .header-large-box > a {
    width: 30%;
  }
  .ui.grid.header-buttons > .row > .column button.ui.button {
    padding: 1.3rem !important;
  }
  /* breadcumb box */
  .breadcumb-box-account-cart {
    padding: 0.75rem 0;
    display: none;
  }
  .search-responsive {
    margin: 0px;
    padding-top: 0px !important;
    width: 100%;
  }

  .search-field-container .search-responsive {
    border-bottom: 1px solid #000;
  }

  .header-search-popup-content {
    top: 5px !important;
  }

  button.ui.button.header-icon-button i.icon {
    padding: 0.75rem 0 0.75rem 0.875rem;
  }

  .maintenance_message {
    padding: var(--card-responsive-spacing) var(--card-spacing)
      var(--card-responsive-spacing) var(--card-spacing-sm) !important;
  }

  .maintenance_detail > .content {
    padding-right: 0 !important;
  }

  .maintenance_detail > .content > i:first-child {
    display: none;
  }

  .maintenance_detail > .content > i:last-child {
    position: absolute;
    right: 10px;
    top: 5px;
    font-size: var(--fs-77) !important;
  }

  .sticky-mode-on {
    left: 0;
  }
}

@media (max-width: 991px) {
  .landing-page-title {
    margin-top: 0 !important;
  }

  .sticky-mode-on {
    left: 0;
  }
}

@media (max-width: 575px) {
  button.ui.button.header-icon-button.header-language-icon {
    display: none;
  }

  .sticky-mode-on {
    left: 0;
  }
}

@include r.bosch_ads_625x_screen_and_up {
  .header-logo-container {
    @include m.setStyleForFlexBox;
    width: max-content;

    height: 100%;

    & img {
      height: 80%;
    }
  }
}
