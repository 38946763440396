@import '../../../styles/mixins.module';

.dialog-modal {
  outline: none !important;
  > div:first-child {
    background-color: white !important;
    > div:first-child,
    > div:nth-child(2),
    > hr {
      display: none !important;
    }
    > div:last-child {
      padding: 0 !important;
      > div:first-child {
        margin: 0 !important;
      }
    }
  }

  .qr-container {
    border: none !important;
    padding: 1rem;
    width: fit-content;
  }
}
