.wrapper {
  min-height: 3.75rem !important;
  display: flex;
  align-items: center;
  background-color: var(--boschGray95) !important;
  width: 100%;
  justify-content: space-between;
  padding-left: 1rem !important;
  padding-right: 1rem !important;
}
.title_container {
  min-height: 2.25rem !important;
  display: flex;
  align-items: center;
  margin: auto 0 !important;
}
.title_container > * {
  font-size: var(--text-l) !important;
  font-weight: bold;
}
/* .actions_container {
  margin-right: 2rem !important;
} */
