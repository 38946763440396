.accordion-panel div.title {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.accordion-panel div.content a:not(:last-child):after {
  margin: 0 1rem;
  content: "|";
  color: var(--boschBlue50);
}
.accordion-panel i.icon {
  font-size: 30px;
  height: 100%;
}

@media (max-width: 767px) {
  .landing-according-title > h4 {
    font-size: 1.5rem !important;
  }
}
