@use "../../../../styles/mixins.module" as m;

.container {
  & .wrapper {
    @include m.setStyleForFlexBox(
      column,
      flex-start,
      flex-start,
      var(--card-spacing-sm)
    );

    & .block {
      @include m.setStyleForFlexBox(row, center, flex-start);
      gap: var(--card-spacing-sm) !important;
    }
  }

  .dropdown_button_item {
    display: flex !important;
    align-items: center;
    justify-content: space-between !important;
    width: 100%;

    & > button {
      background-color: transparent !important;
      margin: 0;
      padding: 0;

      & img {
        width: 1.5rem;
        filter: invert(64%) sepia(98%) saturate(1039%) hue-rotate(6deg)
          brightness(111%) contrast(105%);
      }
    }
  }

  .add_new_button {
    display: flex;
    align-items: center;
    justify-content: flex-start;

    width: 100%;

    & i {
      width: 1.5rem;
      height: 1.5rem;
      font-size: 1.5rem !important;
      margin-right: 0.5rem !important;
      margin-left: 0 !important;
    }
  }
}
