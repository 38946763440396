/*Start incompatiblebrowser-modal*/
.incompatiblebrowser-modal {
  display: flex;
  justify-content: center !important;
}

.incompatiblebrowser-modal.warning > .modal {
  border-top: 6px solid var(--boschYellow85);
}

.incompatiblebrowser-modal.error > .modal {
  border-top: 6px solid var(--boschRed50);
}

.incompatiblebrowser-modal.success > .modal {
  border-top: 6px solid var(--boschGreen50);
}

.incompatiblebrowser-modal > .modal > .header {
  border: 0 !important;
  padding-bottom: 0 !important;
  align-items: center;
  display: flex;
}

.incompatiblebrowser-modal > .modal > .header > i {
  font-size: 1.25em;
}

.incompatiblebrowser-modal > .modal > .content > .ui.grid {
  margin: 0;
}

.incompatiblebrowser-modal > .modal > .actions {
  border: 0 !important;
  padding-top: 0 !important;
}

.incompatiblebrowser-modal > .modal > .actions > button {
  min-width: 125px;
}
/*End incompatiblebrowser-modal*/
