.public-translate-modal {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.public-translate-modal .colorBar {
  width: 100%;
  height: 20px;
  background-color: green;
}

.public-translate-modal .error-area {
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 0px 25px 0px 25px;
}
.public-translate-modal .error-area .dropdown--option {
  display: flex;
  flex-direction: column;
  gap: 10px;
  justify-content: space-between;
}
.public-translate-modal
  .error-area
  .dropdown--option
  .dropdown--option-language-code {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  font-size: 15px;
}

.public-translate-modal .error-area .btn--option {
  margin-top: 25px;
  display: flex;
  flex-direction: row;
  justify-content: end;
  margin-bottom: 15px;
  gap: 5px;
}
.public-translate-modal .error-area .btn--option .button {
  border-radius: 0% !important;
  font-weight: 300 !important;
}

.public-translate-modal .error--title {
  display: flex;
  flex-direction: row;
  gap: 5px;
  justify-content: flex-start;
  align-items: center;
}

.public-translate-modal .error--title p {
  font-size: 13px;
  font-weight: 600;
}
.public-translate-modal .error--content p {
  font-size: 13px;
  font-weight: 500;
}

.public-translate-modal .error--title .Boschicon {
  font-size: 30px;
  font-weight: bold;
}

.ui.search.dropdown > .text {
  overflow: hidden;
  text-overflow: ellipsis;
  word-wrap: break-word;
  word-break: break-all;

  width: 100%;
}

@media only screen and (min-width: 1200px) {
  .ui.mini.modal {
    width: 400px !important;
  }
}
