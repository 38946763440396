.credit-modal-purchase-success {
  width: 40% !important;
  min-width: 470px;
}

.btn-continue-shopping {
  min-width: 210px !important;
}

.select-wholesaler-modal-content {
  overflow: visible !important;
}

.button-block {
  width: 100%;
}
