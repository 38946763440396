@use "../../../styles/dimension.module" as d;
@use "../../../styles/responsive.module" as r;

.header_bar {
  max-width: 100%;
  width: 100%;
  background-color: white;
}

.header_bar > div {
  max-width: d.$base_screen_max_width !important;
  margin: 0 auto !important;
}

.inner_header_bar {
  width: 100% !important;
  max-width: d.$base_screen_max_width;
  margin: auto !important;
  padding: 0 !important;
  background-color: var(--boschGray95) !important;
}

.inner_header_bar .inner_header_bar_row {
  width: 100%;
  margin: 0 !important;
  padding: var(--card-spacing-sm) !important;
  align-items: center;
}

.connecter_status {
  display: flex !important;
  padding-right: 0 !important;
  justify-content: flex-end !important;
  align-items: center;
}

.connecter_status div {
  width: 100%;
}

.header_content {
  padding: 0 !important;
  color: var(--boschBlack);
  font-size: 1.75rem;
  font-weight: bold;
  line-height: 1.28571429em;
  display: flex !important;
  align-items: center !important;
}

@include r.bosch_ads_625x_screen_and_up {
  .header_bar > div {
    max-width: d.$bosch_ads_625x_screen_max_width !important;
  }

  .inner_header_bar {
    max-width: d.$bosch_ads_625x_screen_max_width;
  }
}
