.sc-launcher {
  width: 60px;
  height: 60px;
  background-color: var(--boschBlue50);
  position: fixed;
  right: 25px;
  bottom: 25px;
  border-radius: 50%;
  transition: box-shadow 0.2s ease-in-out;
  z-index: 8;
  text-align: center;
  align-content: center;
  display: flex;
  justify-content: center;
  cursor: pointer;
  > i {
    color: var(--boschWhite) !important;
    font-size: 2.5rem !important;
    align-self: center;
  }
  .sc-count-unseen {
    position: absolute;
    right: 0px;
    bottom: 46px;
    width: var(--fs-60);
    height: var(--fs-60);
    text-align: center;
    z-index: 9999;
    background-color: var(--boschRed50);
    color: var(--boschWhite);
    justify-content: normal;
    border-radius: 50%;
    > i {
      font-size: 18px !important;
    }
  }
}

.sc-launcher.opened {
  visibility: hidden;
}

.sc-launcher:hover {
  box-shadow: 0 0px 27px 1.5px rgba(0, 0, 0, 0.2);
}
