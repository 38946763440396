@use "../../styles/responsive.module" as r;
@use "../../styles/mixins.module" as m;

.container {
  overflow: visible !important;
  height: 100% !important;
  z-index: 9999;
  transition: 0.5s;

  &::-webkit-scrollbar {
    display: none !important;
  }

  &.open {
    :global(.m-side-navigation__header__label) {
      opacity: 1;
      padding: 0.5rem 1.2rem 0.5rem 1rem;
    }

    :global(.m-side-navigation__header__trigger) {
      &:hover,
      &:active {
        background-color: var(--boschGray40);
        & > i {
          color: var(--boschWhite);
        }
      }

      & > i {
        color: var(--boschWhite);
      }
    }
  }

  & :global(.m-side-navigation__header__label) {
    opacity: 0;
    padding: 0;
    transition: 0.5s;
  }

  & :global(.m-side-navigation__header__trigger) {
    &:hover,
    &:active {
      background-color: var(--boschGray20);
      & > i {
        color: var(--boschWhite);
      }
    }
    & > i {
      color: var(--boschBlack);
    }
  }

  & :global(.m-side-navigation__menuItems) {
    height: calc(100vh - 0.375rem - 3rem);
    overflow-y: auto;
  }

  & :global(.m-side-navigation__menuItem) {
    &:hover {
      & i {
        pointer-events: none !important;
      }
    }
    & :global(.m-side-navigation__link) {
      height: auto !important;
      & :global(.m-side-navigation__label) {
        @include m.handleOversizeText(1);
        width: 100% !important;
      }
    }
  }

  .wrapper {
    padding-bottom: var(--card-spacing-sm);
    flex: 1 1 auto;
    &::-webkit-scrollbar {
      display: none !important;
    }
    & i {
      pointer-events: none !important;
      color: var(--boschWhite);
    }
  }
}

@include r.laptop_s_screen_and_up {
  .container {
    height: 100% !important;
    &.open {
      width: 15rem !important;
      & :global(.m-side-navigation__header__trigger) {
        &:hover {
          background-color: var(--boschGray40);
        }
        & > i {
          color: var(--boschWhite);
        }
      }
    }

    & :global(.m-side-navigation__header__trigger) {
      &:hover {
        background-color: var(--boschGray40);
      }

      &:active {
        background-color: var(--boschGray20);
      }

      & > i {
        color: var(--boschWhite);
      }
    }

    & :global(.m-side-navigation__menuItems) {
      overflow: auto;
      height: 100% !important;
    }

    & :global(.m-side-navigation__menuItem) {
      &.open {
        height: 100% !important;
      }

      & :global(.m-side-navigation__link) {
        height: auto !important;
        & :global(.m-side-navigation__label) {
          width: 100% !important;
        }
      }
    }

    .wrapper {
      overflow-y: auto;
      height: 60vh;
      & i {
        pointer-events: none !important;
        color: var(--boschWhite);
      }
    }
  }
}
