/* BLOCK BODY SCROLL-Y IF MENU HEADER MOBILE IS OPENING */
body:has(.menu_mobile) {
  overflow-y: hidden;
}

.menu_mobile {
  position: fixed;
  background-color: var(--boschWhite);
  display: flex;
  flex-direction: column;

  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 110;
}

.super_graphic_wrapper {
  display: flex;
  height: var(--super-graphic-height);

  width: 100%;
}

.main_content {
  display: flex;
  flex-direction: column;

  width: 100%;
  padding: 0 var(--card-spacing-sm);
}

/* MENU MOBILE HEADER */
.menu_mobile_header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex: initial;

  width: 100%;
}

.menu_mobile_header_left,
.menu_mobile_header_right {
  display: flex;
  align-items: center;
}

.menu_mobile_header_left {
  justify-content: flex-start;
}

.menu_mobile_header_right {
  justify-content: flex-end;
}

.menu_mobile_icon_button {
  position: relative;
  box-shadow: none !important;
  font-size: var(--fs-70) !important;
  padding: var(--card-spacing-xs) !important;
}

.menu_mobile_icon_button i {
  font-size: var(--fs-70) !important;
}

.change_lang_button > span {
  position: absolute;
  transform: translate(-50%, -50%);
  font-size: var(--fs-100);
  color: var(--boschBlack);

  top: 45%;
  left: 30%;
}

.menu_mobile_close_button,
.change_lang_button {
  padding: 0 !important;
}

/* MENU MOBILE MAIN - RENDER ALL MENU ITEMS */
.menu_mobile_main {
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: stretch;
  overflow-x: hidden;

  width: 100%;
  flex: 1 1 0%;
}

.menu_mobile_lv1,
.menu_mobile_lv2 {
  display: flex;
  flex-direction: column;
  background-color: var(--boschWhite);

  width: 100%;
  height: 100%;
}

.menu_mobile_item_button,
.menu_mobile_item_button_with_arrow_end {
  color: var(--boschBlack) !important;
  display: flex !important;
  align-items: center;
  box-shadow: none !important;
  flex-direction: row-reverse;
  gap: var(--fs-90);

  padding-left: 0 !important;
  padding-right: 0 !important;
}

.menu_mobile_item_button {
  justify-content: flex-end;
}

.menu_mobile_item_button i,
.menu_mobile_item_button_with_arrow_end i {
  font-size: var(--fs-70) !important;
}

.menu_mobile_item_button_with_arrow_end {
  justify-content: space-between;
}

.menu_mobile_region_button {
  color: var(--boschBlue50) !important;
  fill: var(--boschBlue50) !important;
  flex-direction: row;
  justify-content: flex-start;
  font-weight: bolder !important;
}

/* MENU ITEMS LEVEL 2 CONTAINER */
.menu_mobile_lv2 {
  position: absolute;

  top: 0;
  left: 0;
  animation: move-to-left 0.3s;
}

@keyframes move-to-left {
  0% {
    left: 100%;
  }

  100% {
    left: 0;
  }
}

.menu_mobile_level_back_button {
  flex-direction: row;
  justify-content: flex-start;

  margin-bottom: var(--card-spacing-sm) !important;
}

.menu_mobile_level_back_button > span {
  font-weight: bolder;
}

@media screen and (min-width: 768px) {
  .menu_mobile {
    display: none;
  }
}
