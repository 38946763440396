@import "../../../styles/mixins.module";
@import "../../../styles/responsive.module";

@include card-background;

.main-box {
  width: 800px;
  height: calc(100% - 120px);
  max-height: 30rem;
  position: fixed;
  right: 1rem;
  bottom: 1rem;
  box-sizing: border-box;
  box-shadow: 0px 0px 15px 10px rgba(148, 149, 150, 0.3);
  background: var(--boschWhite);
  display: flex;
  flex-direction: row;
  transition: 0.3s ease-in-out;
  z-index: 5;
  .menu-content {
    width: 3rem;
    border-right: 1px solid var(--boschGray55);
    &.open {
      width: 15rem;
    }
  }
  .main-content {
    flex: 1;
    width: 100%;
  }
}

@media (max-width: 824px) {
  .main-box {
    width: 100%;
    height: 100%;
    max-height: 100%;
    right: 0px;
    bottom: 0px;
    border-radius: 0px;
    transition: 0.1s ease-in-out;
  }
}

@media (max-width: 1260px) {
  .main-box {
    .menu-content {
      width: 3rem;
      &.open {
        width: 19rem;
      }
    }
  }
}

@include laptop_s_screen_and_up {
  .main-box {
    .main-content {
      width: calc(100% - 3rem);
    }
  }
}
