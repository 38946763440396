@use "../../../../styles/mixins.module" as m;

.container {
  & .header {
    @include m.setStyleForFlexBox(
      row,
      space-between,
      center,
      var(--card-spacing-sm)
    );

    & > div:first-child {
      width: max-content;
    }
  }

  & .wrapper {
    @include m.setStyleForFlexBox(
      column,
      flex-start,
      flex-start,
      var(--card-spacing-sm)
    );

    & .vehicle_info_wrapper {
      @include m.setStyleForFlexBox(
        column,
        flex-start,
        flex-start,
        var(--card-responsive-spacing)
      );

      & .vehicle_info_title {
        @include m.setStyleForFlexBox(row, space-between);

        & > div:first-child {
          width: max-content !important;
        }

        & .reset_button {
          @include m.setStyleForFlexBox(row, flex-end);
          width: fit-content !important;
          cursor: pointer;

          gap: 0.25rem !important;

          & > i,
          & > span {
            color: var(--boschBlue50);
          }

          & > i {
            transform: scaleX(-1);
          }
        }
      }

      & .vehicle_info {
        @include m.setStyleForFlexBox(
          column,
          flex-start,
          flex-start,
          var(--card-spacing-sm)
        );

        & .block {
          @include m.setStyleForFlexBox(row, center, flex-start);
          gap: var(--card-spacing-sm) !important;
        }
      }
    }
  }
}
