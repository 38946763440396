@use "../../../../styles/mixins.module" as m;

.container {
  & .wrapper {
    @include m.setStyleForFlexBox(
      column,
      flex-start,
      flex-start,
      var(--card-spacing-sm)
    );

    & .block {
      @include m.setStyleForFlexBox;
      gap: var(--card-spacing-sm) !important;
    }

    & .bottom {
      @include m.setStyleForFlexBox(
        row,
        flex-start,
        center,
        var(--card-spacing-sm)
      );
    }
  }
}
