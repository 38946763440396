.rd_input_group {
  position: relative;
  display: flex !important;
  flex-direction: column !important;

  width: 100%;
}
.title {
  font-size: var(--fs-90);
  position: absolute;
  left: 0;
  top: 0;
  z-index: 2;
  margin: 0.25rem 0.75rem auto 1rem;
  line-height: 1.5 !important;
  pointer-events: none;
  word-break: break-all;
  -webkit-line-clamp: 1 !important;
  line-clamp: 1 !important;
}

.rd_input {
  z-index: 0 !important;
  border-radius: 0 !important;
  height: 3rem !important;
  width: 100% !important;
  /* padding: 0 2.75rem 0 1rem;
  padding-bottom: 0.3125rem;
  padding-top: 1.125rem;
  position: absolute !important;
  left: 0;
  top: 0; */
}
.rd_input[class*="input"] > input {
  /* padding-bottom: 0.3125rem;
  padding-top: 1.125rem; */
  height: 3rem !important;
  width: 100% !important;
  background-color: var(--boschGray90) !important;
  position: absolute;
  border-radius: 0 !important;
  border: none !important;
  border-bottom: 1px solid var(--boschBlack) !important;
  padding: 0 1rem 0 1rem;
  color: var(--boschBlack) !important;
  opacity: 1 !important;
}
.rd_input.with_title > input {
  padding-bottom: 0.3125rem !important;
  padding-top: 1.125rem !important;
}
.rd_input > input:hover {
  background-color: var(--boschGray80) !important;
}

.rd_input > input:focus {
  background-color: var(--boschBlue90) !important;
}
.rd_input[class*="input"].disabled {
  opacity: 1 !important;
}
.rd_input[class*="input"].disabled > input {
  border-bottom-color: var(--boschGray55) !important;
  background-color: var(--boschGray90) !important;
  color: var(--boschBlack) !important;
}
.error,
.information {
  width: 100%;
  padding: 0.25rem 1rem;
  color: var(--boschBlack) !important;
  display: flex;
}

.error {
  background-color: var(--boschRed90) !important;
}

.error > i {
  font-size: 1.5rem !important;
}

.information {
  background-color: var(--boschBlue80) !important;
}

@media screen and (max-width: 557px) {
  .rd_input[class*="input"] > input {
    text-overflow: ellipsis;

    padding-right: 1rem !important;
    -webkit-line-clamp: 1 !important;
    line-clamp: 1 !important;
  }
}
