.service_work {
  width: 70% !important;
  vertical-align: bottom !important;
  padding-top: 4rem !important;
  padding-bottom: var(--card-spacing-sm) !important;
  padding-left: var(--card-spacing-lg) !important;
  padding-right: var(--card-spacing-lg) !important;
}

.service_work_title {
  display: grid !important;
  grid-template-columns: 4fr 2.5fr 4fr 2fr 4.5fr;
  padding-bottom: 0 !important;
}

.service_work_title > div {
  text-align: center;
  width: auto !important;
  font-size: var(--fs-normal);
  padding-top: var(--card-responsive-spacing) !important;
}

.journey_image {
  width: 100% !important;
  padding: 0 var(--card-spacing-sm);
}

.service_work_title > div > p {
  margin: 0 calc(-1 * var(--card-spacing-lg));
}

.step_title {
  font-weight: 700;
  color: var(--boschGreen50);
}

.portrait {
  padding: 0 !important;
  margin: 0 !important;
  width: 30% !important;
}

.portrait > svg {
  width: 93% !important;
  margin-bottom: -0.4rem !important;
}

@media (min-width: 1200px) {
  .grid_title_columns > .column {
    font-size: 1.15rem !important;
  }
}

@media (max-width: 1199px) {
  .portrait > svg {
    display: none;
  }
  .service_work {
    width: 100% !important;
  }
}
