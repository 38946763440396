@use "./mixins.module" as m;

.o-minimal-header__top {
  transition-duration: 0.5s !important;
}

.m-side-navigation__header__label {
  display: inline-block !important;
  @include m.handleOversizeText(1);
  white-space: nowrap !important;
}

.m-side-navigation {
  display: flex !important;
  flex-direction: column;
}

.m-side-navigation__menuItems {
  display: flex;
  flex-direction: column;

  flex: 1 1 0% !important;

  &::-webkit-scrollbar {
    display: none;
  }

  & .m-side-navigation__label,
  & i {
    color: var(--boschWhite);
  }

  & .m-side-navigation__label {
    @include m.handleOversizeText(1);
  }

  & .m-side-navigation__menuItem {
    height: fit-content !important;
  }

  & .m-side-navigation__menuSubitems .m-side-navigation__menuItem i {
    width: 1.5rem;
  }
}

.m-side-navigation.-open .m-side-navigation__link {
  height: 3rem !important;
}

input {
  outline: none !important;
}

input:focus-visible {
  border: none !important;
  border-bottom: 0.0625rem solid var(--boschBlack) !important;
}

.a-checkbox,
.a-button {
  -webkit-tap-highlight-color: transparent;
}

li {
  padding: unset !important;
  font-size: unset !important;

  &::before {
    background-color: transparent !important;
  }
}

.m-step-indicator__step {
  &::before {
    background-color: var(--small__enabled__fill__default) !important;
  }

  &.-active {
    &::before {
      background-color: var(--major-accent__enabled__fill__default) !important;
    }
  }
}

.a-toggle {
  position: relative;
  display: flex;
  align-items: center;

  gap: 0.5rem;
}

.a-toggle input[type="checkbox"] {
  position: absolute;
  appearance: none;

  outline: 0;
}

.a-toggle input[type="checkbox"]:checked + .a-toggle__box {
  background-color: var(--major-accent__enabled__fill__default);
}

.a-toggle__box {
  position: relative;
  cursor: pointer;
  background: var(--small__enabled__fill__default);

  border-radius: 0.75rem;
  height: 1.5rem;
  width: 3rem;
}

.a-toggle input[type="checkbox"]:checked + .a-toggle__box:after {
  background-color: var(--major-accent__enabled__front__default);
  left: 1.875rem;
}

.a-toggle__box:after {
  background-color: var(--small__enabled__front__default);
  border-radius: 0.625rem;
  content: "";
  height: 0.75rem;
  left: 0.375rem;
  position: absolute;
  top: 0.375rem;
  transition: left 0.2s ease-out, background-color 0.1s ease-out;
  width: 0.75rem;
}

@media (min-width: 1194px) and (max-width: 1279px) {
  .m-side-navigation {
    width: 0 !important;
  }

  .m-side-navigation.-open {
    width: 19rem !important;
  }
}
