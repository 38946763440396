@use "../../../styles/responsive.module" as r;

/*Start add-to-cart-success-modal*/
.add-to-cart-success-modal {
  display: flex;
  justify-content: center !important;
}

.add-to-cart-success-modal.warning > .modal {
  border-top: 6px solid var(--boschYellow85);
}

.add-to-cart-success-modal.error > .modal {
  border-top: 6px solid var(--boschRed50);
}

.add-to-cart-success-modal.success > .modal {
  border-top: 6px solid var(--boschGreen50);
}

.add-to-cart-success-modal > .modal > .header {
  border: 0 !important;
  padding-bottom: 0 !important;
  align-items: center;
  display: flex;
  font-size: 1.25rem !important;
}

.add-to-cart-success-modal > .modal > .header > i {
  font-size: 1.25em;
}

.add-to-cart-success-modal > .modal > .content > .ui.grid {
  margin: 0;
}

/* .add-to-cart-success-modal > .modal > .content > .ui.grid p{
    margin-bottom: .5rem;
} */

.add-to-cart-success-modal > .modal > .actions {
  border: 0 !important;
  padding-top: 0 !important;
  padding-bottom: 1rem !important;
  display: flex;
  justify-content: flex-end;
  gap: var(--card-spacing-sm) !important;
}

.add-to-cart-success-modal > .modal > .actions > button {
  min-width: 125px;
  margin: 0 !important;
  font-weight: normal !important;
}

.add-to-cart-success-modal > .modal > .actions > button.left-button {
  margin-right: 0.5rem !important;
  /* background-color: var(--boschRed50);
    border-color: var(--boschRed50) !important;
    color: var(--boschWhite); */
}

@media (max-width: 767px) {
  .add-to-cart-success-modal > .modal > .header {
    font-size: 1rem !important;
  }

  .add-to-cart-success-modal > .modal > .content > .ui.grid p {
    font-size: 0.813rem !important;
  }

  .add-to-cart-success-modal > .modal > .actions > button {
    font-size: 0.875rem !important;
  }

  .add-to-cart-success-modal > .modal > .actions {
    gap: var(--card-responsive-spacing) !important;
  }
}
/*End add-to-cart-success-modal*/

@include r.desktop_m_screen_and_up {
  .add-to-cart-success-modal.success > .modal {
    width: 35.625rem !important;
  }
}
