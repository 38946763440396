@use "./styles/reset";
@use "./styles/frok";

:root {
  --boschWhite: #ffffff;
  --boschBlack: #000000;
  --boschBlack20: rgba(34, 36, 38, 0.15);
  --boschBlue95: #e8f1ff;
  --boschBlue90: #d1e4ff;
  --boschBlue85: #b8d6ff;
  --boschBlue80: #9dc9ff;
  --boschBlue70: #56b0ff;
  --boschBlue60: #0096e8;
  --boschBlue50: #007bc0;
  --boschBlue45: #006ead;
  --boschBlue40: #00629a;
  --boschBlue35: #005587;
  --boschBlue30: #004975;
  --boschGray100: #d9d9d9;
  --boschGray95: #eff1f2;
  --boschGray90: #e0e2e5;
  --boschGray80: #c1c7cc;
  --boschGray70: #a4abb3;
  --boschGray75: #b2b9c0;
  --boschGray65: #979ea4;
  --boschGray60: #8a9097;
  --boschGray55: #7d8389;
  --boschGray50: #71767c;
  --boschGray40: #595e62;
  --boschGray35: #4e5256;
  --boschGray30: #43464a;
  --boschGray20: #2e3033;
  --boschGray20-gradient10: rgb(46, 48, 51, 0.1);
  --boschRed90: #ffd9d9;
  --boschRed85: #ffc6c6;
  --boschRed50: #ed0007;
  --boschRed45: #d50005;
  --boschRed40: #be0004;
  --boschGreen95: #e2f5e7;
  --boschGreen90: #b8efc9;
  --boschGreen85: #9be4b3;
  --boschGreen80: #86d7a2;
  --boschGreen70: #5ebd82;
  --boschGreen60: #37a264;
  --boschGreen50: #00884a;
  --boschGreen45: #007a42;
  --boschGreen40: #006c3a;
  --boschGreen30: #00512a;
  --boschYellow90: #ffdf95;
  --boschYellow85: #ffcf00;
  --boschSilver: #eff1f2;
  --boschPurple50: #c535bc;
  --boschPurple40: #9e2896;
  --boschTurquoise50: #18837e;
  --boschTurquoise45: #147671;
  --floating-background-color: #00000040;
}

:root {
  --boschFont: Bosch Sans, "Helvetica Neue", Helvetica, Arial, sans-serif !important;
}

:root {
  --card-spacing: 1.5rem;
  --card-spacing-sm: 1rem;
  --card-spacing-xs: 0.75rem;
  --card-spacing-lg: 2rem;
  --card-spacing-xl: 2.5rem;
  --card-spacing-horizontal: 1.5rem 1rem;
  --card-spacing-vertical: 1rem 1.5rem;
  --card-spacing-sm-horizontal: 1rem 0.5rem;
  --card-spacing-sm-vertical: 0.5rem 1rem;
  --card-border-radius: 0;
  --card-responsive-spacing: 0.5rem;
}

:root {
  --input-border-radius: 0.25rem;
}

:root {
  --fs-10: 4rem; /*64px: For header of home page*/
  --fs-20: 2.5rem; /*40px*/
  --fs-30: 2.25rem; /*36px For section header*/
  --fs-40: 2rem; /*32px*/
  --fs-50: 1.75rem; /*28px*/
  --fs-60: 1.5rem; /*24px*/
  --fs-70: 1.25rem; /*20px*/
  --fs-75: 1.125rem; /*18px Modal header*/
  --fs-77: 0.875rem; /*14px Modal header*/
  --fs-80: 0.813rem; /*13px small text*/
  --fs-normal: 1rem; /*16px small text*/
  --fs-90: 0.75rem;
  --fs-100: 0.625rem; /*10px small text*/
}

:root {
  --text-s: 0.75rem;
  --text-m: 1rem;
  --text-l: 1.25rem;
  --text-xl: 1.5rem;
  --text-2xl: 2rem;
  --text-3xl: 2.5rem;
  --text-4xl: 3rem;
  --text-5xl: 4rem;
  --text-6xl: 5rem;
}

:root {
  --CDM3-max-width: 1300px;
  --CDM2-max-width: 1120px;
}

:root {
  --footer-bgr-clr: var(--boschGray20);
  --footer-content-clr: var(--boschWhite);
  --footer-link-hovered-clr: var(--boschBlue70);
  --footer-language-dropdown-bgr-clr: var(--boschGray30);
  --footer-language-dropdown-selected-item-bgr-clr: var(--boschGray35);
  --footer-icon-share-size: var(--fs-60);
}

:root {
  --button-min-width: 9.375rem;
  --button-padding-lg: 1rem 2rem;
}

:root {
  --super-graphic-height: 0.375rem;
  --full-menu-header-z-index: 10;
}

:root {
  --statistic-chart-max-height: 24rem;
}

/* MAJOR SIGNAL schemes that are followed on Bosch Style */
/* bgr: background color | frg: foreground - for text color */
:root {
  /* For PRIMARY BACKGROUND (white) */
  --primary-major-signal-neutral-default-bgr: var(--boschBlue50);
  --primary-major-signal-neutral-default-fgr: var(--boschWhite);

  --primary-major-signal-error-default-bgr: var(--boschRed50);
  --primary-major-signal-error-default-fgr: var(--boschWhite);

  --primary-major-signal-warning-default-bgr: var(--boschYellow85);
  --primary-major-signal-warning-default-fgr: var(--boschBlack);

  --primary-major-signal-success-default-bgr: var(--boschGreen50);
  --primary-major-signal-success-default-fgr: var(--boschWhite);

  /* For SECONDARY BACKGROUND (gray) */
  /* THE SAME with PRIMARY BACKGROUND, nothing changes */
}

/* MINOR SIGNAL schemes that are followed on Bosch Style */
/* bgr: background color | frg: foreground - for text color */
:root {
  /* For PRIMARY BACKGROUND (white) */
  --primary-minor-signal-neutral-default-bgr: var(--boschBlue90);
  --primary-minor-signal-neutral-default-fgr: var(--boschBlack);

  --primary-minor-signal-error-default-bgr: var(--boschRed90);
  --primary-minor-signal-error-default-fgr: var(--boschBlack);

  --primary-minor-signal-warning-default-bgr: var(--boschYellow90);
  --primary-minor-signal-warning-default-fgr: var(--boschBlack);

  --primary-minor-signal-success-default-bgr: var(--boschGreen90);
  --primary-minor-signal-success-default-fgr: var(--boschBlack);

  /* For SECONDARY BACKGROUND (gray) */
  --secondary-minor-signal-neutral-default-bgr: var(--boschBlue85);
  --secondary-minor-signal-neutral-default-fgr: var(--boschBlack);

  --secondary-minor-signal-error-default-bgr: var(--boschRed85);
  --secondary-minor-signal-error-default-fgr: var(--boschBlack);

  --secondary-minor-signal-warning-default-bgr: var(--boschYellow85);
  --secondary-minor-signal-warning-default-fgr: var(--boschBlack);

  --secondary-minor-signal-success-default-bgr: var(--boschGreen85);
  --secondary-minor-signal-success-default-fgr: var(--boschBlack);
}

/* MAJOR HIGHLIGHT schemes that are followed on Bosch Style */
/* bgr: background color | frg: foreground - for text color */
:root {
  /* For PRIMARY BACKGROUND (white) */
  --primary-major-highlight-purple-default-bgr: var(--boschPurple40);
  --primary-major-highlight-purple-default-fgr: var(--boschWhite);

  --primary-major-highlight-blue-default-bgr: var(--boschBlue50);
  --primary-major-highlight-blue-default-fgr: var(--boschWhite);

  --primary-major-highlight-turquoise-default-bgr: var(--boschTurquoise50);
  --primary-major-highlight-turquoise-default-fgr: var(--boschWhite);

  --primary-major-highlight-green-default-bgr: var(--boschGreen50);
  --primary-major-highlight-green-default-fgr: var(--boschWhite);

  /* For SECONDARY BACKGROUND (gray) */
  /* THE SAME with PRIMARY BACKGROUND, nothing changes */
}

/* MINOR HIGHLIGHT schemes that are followed on Bosch Style */
/* bgr: background color | frg: foreground - for text color */
:root {
  /* For PRIMARY BACKGROUND (white) */
  --primary-minor-highlight-purple-default-bgr: transparent;
  --primary-minor-highlight-purple-default-fgr: var(--boschPurple40);

  --primary-minor-highlight-blue-default-bgr: transparent;
  --primary-minor-highlight-blue-default-fgr: var(--boschBlue50);

  --primary-minor-highlight-turquoise-default-bgr: transparent;
  --primary-minor-highlight-turquoise-default-fgr: var(--boschTurquoise50);

  --primary-minor-highlight-green-default-bgr: transparent;
  --primary-minor-highlight-green-default-fgr: var(--boschGreen50);

  /* For SECONDARY BACKGROUND (gray) */
  --secondary-minor-highlight-purple-default-bgr: transparent;
  --secondary-minor-highlight-purple-default-fgr: var(--boschPurple40);

  --secondary-minor-highlight-blue-default-bgr: transparent;
  --secondary-minor-highlight-blue-default-fgr: var(--boschBlue45);

  --secondary-minor-highlight-turquoise-default-bgr: transparent;
  --secondary-minor-highlight-turquoise-default-fgr: var(--boschTurquoise45);

  --secondary-minor-highlight-green-default-bgr: transparent;
  --secondary-minor-highlight-green-default-fgr: var(--boschGreen45);
}

:root {
  --floating-background-shadow: 0 0 0.5rem 0.1rem
    var(--floating-background-color);
  --font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
    "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  --box-arrow-size: 1rem;
}

:root {
  --rd-table-bgr-color: var(--boschWhite);
  --rd-table-cell-padding: 0.75rem 1rem;
  --rd-table-cell-padding-tablet: 0.5rem 0.75rem;
  --rd-table-header-row-frg-color: var(--boschBlack);
  --rd-table-header-row-border-color: var(--boschBlack);
  --rd-table-basic-row-border-color: var(--boschGray80);
  --rd-table-cell-min-height: 3rem;
  --rd-table-content-font-size: var(--text-m);
}

:root {
  --rd-button-min-width: 9.375rem; /* 9.375rem = 150px */
  --rd-button-small-min-width: 7.8125rem; /* 7.8125rem = 125px */
  --rd-button-padding: 0.75rem 1rem;
  --rd-button-small-padding: 0.5rem 0.75rem;
  --rd-button-bgr-color: var(--boschBlue50);
  --dashboard-item-base-width: 10.625rem;
  --dashboard-item-base-height: 9.0625rem;
}

:root {
  --table-background-color: var(--boschGray95);
  --table-mobile-background-color: var(--boschWhite);
  --table-header-border-color: var(--boschGray90);
}
