@use "../../../styles/dimension.module" as d;
@use "../../../styles/responsive.module" as r;

.menu_header {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: var(--card-responsive-spacing);
}

/* MINIMAL MENU HEADER */
.minimal_menu_header {
  justify-content: flex-start !important;
  align-items: center !important;
  border-bottom: none !important;

  margin: 0 !important;
}

.menu_header_title {
  font-weight: bolder;
}

.toggle_menu_btn {
  box-shadow: none !important;
  font-size: var(--fs-60) !important;

  padding: 0 !important;
  margin-top: 8px !important;
}

.menu_header_item {
  border: none !important;
  cursor: pointer;
}

.menu_header_item_selected {
  color: var(--boschBlue50) !important;
}

.menu_header_item:hover {
  color: var(--boschBlue50) !important;
}

/* FULL MENU HEADER */
.full_menu_header {
  z-index: var(--full-menu-header-z-index);
  background: var(--boschWhite);
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: stretch;
  left: calc(-1 * (100vw - d.$base_screen_max_width) / 2);
  padding-top: var(--card-spacing-sm);
  padding-bottom: var(--card-spacing-lg);
  height: max-content;
  border-top: 1px solid var(--boschGray90);

  top: 100%;
  width: 100vw;
  box-shadow: 0px 10px 10px -10px rgba(0, 0, 0, 0.25);
}

.full_menu_header_col {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: calc(d.$base_screen_max_width / 3);
}

.full_menu_header_col_with_divider {
  border-right: 1px solid var(--boschBlack);
}

.full_menu_header_col_lv_2 {
  padding: 0 var(--card-spacing);
}

.full_menu_header_col_button {
  color: var(--boschBlack) !important;
  display: flex !important;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  box-shadow: none !important;

  padding-left: 0 !important;
  padding-right: 0 !important;
}

.full_menu_header_col_button i {
  font-size: var(--fs-60) !important;
}

.full_menu_header_col_button:hover {
  color: var(--boschBlue50) !important;
}

.full_menu_header_col_button:hover > span {
  text-decoration: underline !important;
}

.full_menu_header_col_button_has_children {
  flex-direction: row-reverse;
}

.full_menu_header_col_button_with_arrow {
  flex-direction: row-reverse;
  justify-content: flex-end;

  gap: var(--card-spacing-xs);
}

.full_menu_header_col_button_with_arrow i {
  font-size: var(--fs-normal) !important;
}

.full_menu_header_col_button_selected {
  color: var(--boschBlue50) !important;
}

.full_menu_header_region_button {
  color: var(--boschBlue50) !important;
  fill: var(--boschBlue50) !important;
  margin-top: 0 !important;
  justify-content: flex-start;
  font-weight: bolder !important;
}

.full_menu_header_region_button:hover > span {
  text-decoration: none !important;
}

.full_menu_header_col_divider {
  border-top: 1px solid var(--boschBlack) !important;
  border-bottom: none !important;
}

.full_menu_header_col_title {
  font-weight: bolder;
}

@media (max-width: 1300px) {
  .full_menu_header {
    width: 100vw;
    left: 0;
  }

  .full_menu_header_col {
    width: calc(100% / 3);
  }
}

/* Follow on .grid-responsive-width breakpoint */
@media (max-width: 1152px) {
  .full_menu_header {
    left: calc(-1 * var(--card-spacing-sm));

    padding-left: var(--card-spacing-sm);
    padding-right: var(--card-spacing-sm);
  }

  .menu_header_title {
    display: none;
  }
}

@media (max-width: 767.5px) {
  .menu_header {
    display: none;
  }

  .menu_header_title {
    display: none;
  }
}

@include r.bosch_ads_625x_screen_and_up {
  .full_menu_header {
    left: calc(-1 * (100vw - d.$bosch_ads_625x_screen_max_width) / 2);
  }

  .full_menu_header_col {
    width: calc(d.$bosch_ads_625x_screen_max_width / 3);
  }
}

@include r.desktop_l_screen_and_up {
  .full_menu_header {
    left: calc(-1 * (100vw - d.$base_screen_max_width) / 2);
  }

  .full_menu_header_col {
    width: calc(d.$base_screen_max_width / 3);
  }
}
