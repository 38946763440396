@use "../../../../styles/mixins.module" as m;

.container {
  & .wrapper {
    @include m.setStyleForFlexBox(
      column,
      flex-start,
      flex-start,
      var(--card-spacing-sm)
    );

    & .bottom {
      margin-top: var(--card-spacing-sm);
    }
  }
}
