@font-face {
  font-family: "Bosch-Icon";
  src: url("./assets/fonts/Bosch-Icon.eot");
  src: url("./assets/fonts/Bosch-Icon.eot") format("embedded-opentype"),
    url("./assets/fonts/Bosch-Icon.ttf") format("truetype"),
    url("./assets/fonts/Bosch-Icon.woff") format("woff"),
    url("./assets/fonts/Bosch-Icon.woff2") format("woff2"),
    url("./assets/fonts/Bosch-Icon.svg") format("svg");
  font-weight: normal;
  font-style: normal;
}
.Boschicon {
  font-family: Bosch-Icon !important;
}
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: var(--boschGray20);
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: var(--boschBlue50);
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

button.ui {
  border-radius: 0 !important;
}

button.ui.button.primary-btn {
  background: var(--boschBlue50);
  color: var(--boschWhite);
}
/* Override font family to BOSCH
body{
  font-family:Bosch Sans,'Helvetica Neue',Helvetica,Arial,sans-serif !important;
} */
/* Override font size*/

/*Text 5XL*/
h1 {
  font-size: 4rem !important;
}

/*Text 4XL*/
h2 {
  font-size: 3rem !important;
}

/*Text 3XL*/
h3 {
  font-size: 2.5rem !important;
}

/*Text 2XL*/
h4 {
  font-size: 2rem !important;
}

/*Text XL*/
h5 {
  font-size: 1.5rem !important;
  font-weight: 400 !important;
}

/* body div {
  width: 100%;
} */
/* 
div.app-body {
  display: grid;
  grid-template-columns: minmax(16px, 1fr) minmax(750px, 1170px) minmax(16px, 1fr);
} */
div.app-body-container {
  padding: 0rem 1.875rem;
}

/* grid responsive */
.grid-responsive-width {
  width: calc(100% - 2rem);
  max-width: 1120px;
  margin: 0 var(--card-spacing-sm);
}
.grid-responsive-width > .ui.grid:first-child {
  margin-left: 0 !important;
  margin-right: 0 !important;
  width: 100% !important;
}

button {
  font-weight: normal !important;
}
@media (min-width: 768.1px) and (max-width: 820px) {
  .grid-responsive-width > .ui.grid:first-child {
    width: 100% !important;
    margin: 0 auto !important;
  }

  .grid-responsive-width > .ui.grid:nth-child(2) {
    width: 100% !important;
    margin: 0 auto !important;
  }
}

@media only screen and (min-width: 1152px) {
  .grid-responsive-width {
    width: 100% !important;
    margin: 0 auto !important;
  }
}
/* .body-container {
  padding-top: 4rem;
} */

/* @media only screen and (min-width:768px) and (max-width:991px){
  .grid-responsive-width{
    width: calc(723px + 2rem) !important;
    margin-left: auto !important;
    margin-right: auto !important;
    padding: 0 1rem;
  }
}

@media only screen and (min-width:992px) and (max-width:1199px){
  .grid-responsive-width{
    width: calc(933px + 2rem) !important;
    margin-left: auto !important;
    margin-right: auto !important;
    padding: 0 1rem;
  }
}

@media only screen and (min-width:1200px){
  .grid-responsive-width{
    width: calc(1127px + 2rem) !important;
    margin-left: auto !important;
    margin-right: auto !important;
    padding: 0 1rem;
  }
} */

/* unordered list with green check as bullets */
ul.green-check-as-bullets-ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  line-height: 24px;
}

ul.green-check-as-bullets-ul li {
  display: flex;
}

ul.green-check-as-bullets-ul li::before {
  content: "";
  display: inline-block;
  height: 1.4rem;
  width: 1.4rem;
  margin-right: 0.5rem;
  background-image: url("/src/assets/svg/checkmark-frame.svg");
  vertical-align: sub;
  background-repeat: no-repeat;
  flex: 1;
}

ul.green-check-as-bullets-ul li > p {
  flex: 10;
}

/*primary button*/
button.ui.button.bwc-btn--primary {
  background: var(--boschBlue50);
  color: var(--boschWhite);
}
button.ui.bwc-btn-success {
  background-color: var(--boschGreen50) !important;
  color: var(--boschWhite) !important;
}
button.ui.button.bwc-btn--primary:active {
  background: var(--boschBlue40);
}
button.ui.button.bwc-btn--primary:hover {
  background: var(--boschBlue40);
}
button.ui.button.bwc-btn--secondary {
  background: var(--boschWhite);
  border: 1px solid var(--boschBlue50);
  color: var(--boschBlue50);
}
button.ui.button.bwc-btn--secondary:active,
button.ui.button.bwc-btn--secondary:focus {
  background: var(--boschBlue40);
  border: 1px solid var(--boschBlue40);
  color: var(--boschBlue40);
}
button.ui.button.bwc-btn--secondary:hover {
  background: var(--boschWhite);
  border: 1px solid var(--boschBlue40);
  color: var(--boschBlue40);
}
.ui.primary.button,
.ui.primary.buttons .button {
  background-color: var(--boschBlue50);
}
.ui.primary.button:hover,
.ui.primary.buttons .button:hover {
  background-color: var(--boschBlue40);
}
.ui.button.btn-danger {
  background-color: var(--boschRed50) !important;
  color: var(--boschWhite);
  font-weight: normal;
  border: 1px solid var(--boschRed50) !important;
}
.ui.button.btn-danger:hover {
  background-color: var(--boschRed50) !important;
}
.ui.button.btn-green {
  background-color: var(--boschGreen50);
  color: var(--boschWhite);
}
.ui.button.btn-green:hover {
  background-color: var(--boschGreen50);
}

.ui.modal {
  border-radius: 0 !important;
}

.ui.button.btn-equal {
  min-width: 175px;
}
.ui.card > .content > .description,
.ui.cards > .card > .content > .description {
  color: var(--boschBlack);
}
/*Start pagination*/
.r-pagination {
  border: none !important;
  box-shadow: none !important;
}

.r-pagination > .item::before {
  display: none;
}

.r-pagination > .item {
  border-radius: 50% !important;
  justify-content: center;
  margin-right: 0.25rem;
}
.r-pagination > .active.item,
.r-pagination > a.item.active:hover,
.r-pagination > a.item:focus-visible .r-pagination > a.item:active {
  background-color: var(--boschBlue50) !important;
  color: var(--boschWhite) !important;
}
.r-pagination > a.item:hover {
  background-color: var(--boschBlue40) !important;
  color: var(--boschWhite) !important;
}

.r-pagination.small > .item {
  width: 2.375rem !important;
  height: 2.375rem !important;
}
/*end pagination*/

/*Checkbox*/
.ui.checkbox.fitted.custom-checkbox {
  line-height: 24px;
}
.ui.checkbox.fitted.custom-checkbox > label {
  min-width: unset !important;
}
.ui.checkbox.fitted.custom-checkbox > label:hover::before,
.ui.checkbox.fitted.custom-checkbox input:checked ~ label:hover::before {
  background: #7fc6e7;
}

.ui.checkbox.fitted.custom-checkbox input:checked ~ label::before {
  background: var(--boschBlue50);
}

.ui.checkbox.fitted.custom-checkbox > label::before {
  background: #dfdfe0;
  border: 0;
  width: 16px;
  height: 16px;
  border-radius: 0;
}

.ui.checkbox.fitted.custom-checkbox input:checked ~ label::after {
  content: "";
  box-sizing: border-box;
  border-bottom: 2px solid #fff;
  border-left: 2px solid #fff;
  position: absolute;
  left: 5px;
  top: 3px;
  -webkit-transform: rotate(-44.5deg);
  transform: rotate(-44.5deg);
  -webkit-transform-origin: bottom;
  transform-origin: bottom;
  width: 10px;
  height: 6px;
}
/*End checkbox*/

@media (hover: none) {
  .r-pagination > a.item:hover {
    background-color: inherit !important;
    color: inherit !important;
  }
}
