p.confirm-your-email {
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.confirm-modal-intro {
  margin-bottom: 1.5rem;
}

.confirm-modal-description3 {
  margin-top: 1.5rem;
}

.button-confirm-container {
  display: flex !important;
  justify-content: flex-end !important;
  margin-top: 2rem !important;
}

.button-confirm--responsive {
  width: 30% !important;
}

@media (max-width: 576px) {
  .button-confirm--responsive {
    width: 100% !important;
  }
}
