@use "../../../../styles/mixins.module" as m;

.container {
  @include m.setStyleForFlexBox(row, flex-start);
  width: max-content !important;
  gap: var(--card-responsive-spacing) !important;
  padding-top: var(--card-responsive-spacing);

  &.hover_included {
    cursor: pointer;

    &:hover > .link {
      text-decoration: underline;
    }
  }

  & .link {
    color: var(--boschBlue50);
  }

  & i {
    transform: rotate(180deg);
    fill: var(--boschBlue50);

    margin: 0 !important;

    & svg {
      height: 1rem !important;
    }
  }
}
